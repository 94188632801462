  $global-font-family:                            'Overpass', sans-serif;
  $montserrat-font-family:                        'Montserrat', sans-serif;
  $secondary-font-family:                        -apple-system, BlinkMacSystemFont, "Segoe UI", Roboto, "Helvetica Neue", Arial, sans-serif;
  $global-font-size:                              14px;
  $global-line-height:                            1.4;

  $global-color:                                  #5A5A5A;
  $global-muted-color:                            #3E3E3E;

  $global-link-color:                             #D4AA5C;
  $global-link-hover-color:                       #D4AA5C;

  $global-inverse-color:                          #FFFFFF;

  $global-background:                             #FFFFFF;

  $global-muted-background:                       #EFF0F2;
  $global-primary-background:                     #00398A;
  $global-secondary-background:                   #3BB7FF;

  $global-success-background:                     #32d296;
  $global-warning-background:                     #EB5757;
  $global-danger-background:                      #f0506e;

  $global-border-width:                           1px;
  $global-border:                                 rgba(0, 57, 138, 0.3);

  $global-margin:                                 12px;

  $global-gutter:                                 30px;

  $global-control-height:                         50px;
