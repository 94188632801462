.single-synagogue {
    section.single-synagogue {
        .single-synagogue-layout {
            .item-map {

                .map-info {
                    display: flex;
                    justify-content: space-between;
                    flex-wrap: wrap;
                    background-color: $global-primary-background;
                    padding: 2rem 4rem;

                    .info-item {
                        position: relative;
                        margin-bottom: 16px;
                        padding-left: 46px;
                        width: 400px;
                        color: $global-inverse-color;

                        .item-title {
                            font-size: 18px;
                            color: $global-secondary-background;
                        }

                        a {
                            color: $global-inverse-color;

                            &:hover,
                            &:active,
                            &:focus {
                                color: $global-link-hover-color;
                            }
                        }

                        span {
                            position: absolute;
                            left: 0;
                            top: 8px;
                            font-size: 30px;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .single-cemetery {
        section.single-cemetry {
            .single-cemetry-layout {
                .item-map {

                    .map-info {
                        padding: 2rem;
                    }
                }
            }
        }
    }
}
