[class^="icon-"],
[class*=" icon-"] {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
}

svg {
  max-height: 100%;
  max-width: 100%;
}

svg[class^="icon-"],
svg[class*=" icon-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor;
}

.icon-type:before {
  border: 1px solid $global-link-color;
  color: $global-link-color;
  border-radius: 50%;
}

