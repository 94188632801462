section.services-home {
    margin-bottom: 4rem;
    
    .container {

        .services-home-layout {
            display: flex;
            align-items: stretch;
            justify-content: space-between;
    
            .services-item {
                display: flex;
                max-width: 48%;
                flex-grow: 1;
                min-height: 320px;
    
                background-repeat: no-repeat; 
                background-size: cover;
                background-position: center center;
    
                .item-layout {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    background-color: rgba(27, 84, 189, 0.4);
                    padding: 1rem;
                    outline: 1px solid $global-inverse-color;
                    outline-offset: -1rem;
                    transition: 0.2s;

                    &:hover {
                        background-color: rgba(0,0,0,0);
                        transition: 0.2s;
                    }
    
                    .item-title {
                        font-family: $montserrat-font-family;
                        font-size: 36px;
                        color: $global-inverse-color;
                        font-weight: 600;
                        text-align: center;
                    }

                    .item-action {
                        color: $global-inverse-color;
                        font-size: 1.2rem;
                        text-decoration: underline;
                        text-transform: uppercase;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    section.services-home {
        .container {
            padding: 0 1rem;

            .services-home-layout {
                flex-direction: column;
        
                .services-item {
                    max-width: 100%;
                    flex-grow: 1;
                    width: 100%;
                    margin-bottom: 1rem;
                }
            }
        }
    }
}