section.get-in-touch {
    margin-bottom: 4rem;
    
    .get-in-touch-layout {

        .form-layout {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;

            .layout-item.item-wrap {
                max-width: 370px;
                padding: 0 1rem;
            }

            .layout-item.item-submit {
                width: 100%;
                text-align: center;

                input[type=submit] {
                    &:hover,
                    &:active,
                    &:focus {
                        background-color: $global-secondary-background;
                    }
                }
            }
        }
    }
}





