section.contact-actions {
    background-color: $global-primary-background;
    margin-bottom: 3rem;
    
    .contact-actions-layout {
        display: flex;
        justify-content: space-around;
        flex-wrap: wrap;
        align-items: flex-start;

        padding: 2rem 1rem;

        .layout-item {
            color: $global-inverse-color;
            text-align: center;
            min-width: 200px;
            max-width: 220px;
            margin: 1rem;

            .title {
                font-size: 18px;
                color: $global-secondary-background;
            }

            a {
                color: $global-inverse-color;

                &:hover,
                &:active,
                &:focus {
                    color: $global-link-hover-color;
                }
            }

            span {
                font-size: 3rem;
            }
        }
    }
}