.pagination {
    text-align: center;
    margin-bottom: 20px;

    .page-numbers {
        color: $global-color;
        margin: 8px;

        &:hover,
        &:active,
        &:focus {
            color: $global-link-hover-color;
        }

        &.current {
            color: $global-link-color;
        }
    }
}

.block-pagination {
    display: flex;
    flex-direction: column;
    align-items: center;
    width: 100%;

    .by_number {
        display: flex;
        flex-wrap: wrap; 
    }

    a {
        margin: 8px;
        color: $global-color;

        &:hover,
        &:active,
        &:focus {
            color: $global-link-hover-color;
        }

        &.active {
            color: $global-link-color;
            user-select: none;
            cursor: default;
            pointer-events: none;
        }
    }
}