#fancybox-close {
    top: 12px !important;
    right: 12px !important;
    background: none !important;
    font-size: 18px;

    &:before {
        position: absolute;
        content: "\e905";
        font-family: 'icomoon';
    }
}