section.donation {
    background-color: $global-secondary-background;
    padding: 3rem 0;

    .donation-layout {
        display: flex;
        align-items: center;
        justify-content: space-between;

        p {
            font-family: $montserrat-font-family;
            color: $global-inverse-color;
            max-width: 900px;
            margin: 0.8rem 0;
            font-weight: 600;
            font-size: 26px;
        }

        a {
            min-width: 200px;
        }
    }
}

@media screen and (max-width: 768px) {
    section.donation {
        .donation-layout {
            flex-direction: column;
            text-align: center;
        }
    }
}