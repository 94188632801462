section.records {
    padding: 4rem 0;

    .records-tabs {
        #records-tabs-caption {
            display: flex;
            justify-content: center;
            flex-wrap: wrap;
            list-style: none;
            position: relative;
            padding: 0;
            margin: 8px auto;
            background-color: $global-secondary-background;
    
            label {
                padding: 8px 14px;
                margin: 2px;
                font-size: 24px;
                color: $global-primary-background;
                text-decoration: underline;
                position: relative;
                text-align: center;
                cursor: default;
                font-family: $montserrat-font-family;
                font-weight: 600;
    
                &:not(.active) {
                    cursor: pointer;   
                    color: $global-inverse-color;
    
                    &:hover {
                        color: $global-primary-background;
                    }
                }

                input {
                    opacity: 0;
                }
            }
    
            .active {
                user-select: none;
            }
        }

        .records-tabs-content {

            &.active {
                display: flex;
            }

            .records-tabs-content-layout {
                display: flex;
                flex-wrap: wrap;
                
                .funeral-row {
                    display: flex;
                    flex-wrap: wrap;
                    padding: 1rem 0;

                    p {
                        margin: 0;
                    }

                    .funeral-row-date {
                        width: 100%;
                        text-transform: uppercase;
                        color: $global-link-color;
                        padding: 0 10px;
                        font-weight: bold;
                    }

                    .funeral-services {
                        margin: 10px;

                    }
                }

                hr {
                    margin: 10px;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    section.records {
        padding: 2rem 0;
    
        .records-tabs {
            #records-tabs-caption {
                flex: 1 0 auto;
        
                label {
                    font-size: 18px;
                }
            }
        }
    }
}