.page-template-page-calculator {
    section.content {
        .content-layout {
            display: flex;
            align-items: flex-start;

            .item-text {
                padding: 1rem;

                .schedule-wrapper {
                    .schedule-wrapper-layout {
                        display: flex;
                        align-items: flex-end;
                        background-color: white;

                        .layout-item {
                            padding-right: 1rem;

                            span {
                                font-weight: bold;
                            }

                            .item-title {
                                font-family: $montserrat-font-family;
                                font-size: 24px;
                            }

                            .item-name {
                                text-transform: uppercase;
                                font-weight: 600;
                            }
                        }
                    }
                }
            }

            .item-calculator {
                max-width: 370px;
                min-width: 300px;
                background-color: $global-muted-background;
                padding: 16px;

                #calculator {
                    .form-layout {

                        .layout-item:not(:last-child) {
                            margin-bottom: 1rem;
                        }

                        label {
                            margin: 10px 0;
                        }

                        input[type=checkbox] {
                            margin-right: 6px;
                        }

                        .item-filter {
                            display: flex;
                            justify-content: space-between;
                            align-items: center;
                            select {
                                padding-right: 20px;
                                max-width: 120px;
                            }
                        }

                        .item-date {
                                display: flex;
                                justify-content: space-between;

                            .month {
                                max-width: 120px;
                                select {
                                    padding-right: 20px;
                                }
                            }

                            .day, 
                            .year {
                                max-width: 60px;
                            }
                        }

                        .item-years {
                            label {
                                width: 100%;
                            }
                        }

                        .item-checkbox {
                            display: flex;
                            flex-direction: column;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    .page-template-page-calculator {
        section.content {
            .content-layout {
                flex-direction: column;
                align-items: center;
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .page-template-page-calculator {
        section.content {
            .content-layout {
                .item-text {
                    .schedule-wrapper {
                        .schedule-wrapper-layout {
                            flex-direction: column;
                            align-items: flex-start;
                        }
                    }
                }
            }
        }
    }
}