*,
*::before,
*::after {
  box-sizing: border-box; }

html {
  box-sizing: border-box;
  font-family: "Overpass", sans-serif;
  font-size: 14px;
  font-weight: normal;
  line-height: 1.4;
  -webkit-text-size-adjust: 100%;
  -webkit-tap-highlight-color: transparent;
  background-color: #fff;
  color: #5A5A5A; }

body {
  margin: 0;
  background-color: #fff; }

.wrapper {
  position: relative;
  overflow: hidden;
  min-height: 100vh; }

a {
  color: #D4AA5C;
  text-decoration: none;
  cursor: pointer;
  background-color: transparent;
  -webkit-text-decoration-skip: objects; }

a:active,
a:hover {
  outline: none; }

a:hover {
  color: #D4AA5C;
  text-decoration: none; }

a:not([href]):not([tabindex]) {
  color: inherit;
  text-decoration: none; }

a:not([href]):not([tabindex]):hover,
a:not([href]):not([tabindex]):focus {
  color: #D4AA5C;
  text-decoration: none; }

a:not([href]):not([tabindex]):focus {
  outline: 0; }

abbr[title] {
  border-bottom: none;
  text-decoration: underline;
  text-decoration: underline dotted; }

b,
strong {
  font-weight: bolder; }

:not(pre) > code,
:not(pre) > kbd,
:not(pre) > samp {
  font-family: Consolas, monaco, monospace;
  font-size: 14px;
  color: #f0506e;
  white-space: nowrap;
  padding: 2px 6px;
  background: #f8f8f8; }

em {
  color: #3E3E3E; }

ins {
  background: #5A5A5A;
  color: #ffd;
  text-decoration: none; }

mark {
  background: #ffd;
  color: #5A5A5A; }

q {
  font-style: italic; }

small {
  font-size: 80%; }

sub,
sup {
  font-size: 75%;
  line-height: 0;
  position: relative;
  vertical-align: baseline; }

sup {
  top: -0.5em; }

sub {
  bottom: -0.25em; }

audio,
canvas,
iframe,
img,
svg,
video {
  vertical-align: middle; }

audio,
canvas,
img,
video {
  max-width: 100%;
  height: auto; }

svg:not(:root) {
  overflow: hidden; }

img:not([src]) {
  visibility: hidden; }

p,
ul,
ol,
dl,
pre,
address,
fieldset,
figure {
  margin: 0 0 12px 0; }

* + p,
* + ul,
* + ol,
* + dl,
* + pre,
* + address,
* + fieldset,
* + figure {
  margin-top: 12px; }

h1,
h2,
h3,
h4,
h5,
h6 {
  margin: 0 0 12px 0;
  font-family: "Overpass", sans-serif;
  font-weight: normal;
  color: inherit;
  text-transform: none; }

* + h1,
* + h2,
* + h3,
* + h4,
* + h5,
* + h6 {
  margin-top: 12px; }

h1, .h1 {
  font-size: 2rem;
  line-height: 1.2; }

h2, .h2 {
  font-size: 1.6rem;
  line-height: 1.2; }

h3, .h3 {
  font-size: 1.4rem;
  line-height: 1.2; }

h4, .h4 {
  font-size: 1.2rem;
  line-height: 1.2; }

h5, .h5 {
  font-size: 1rem;
  line-height: 1.2; }

h6, .h6 {
  font-size: 0.872rem;
  line-height: 1.2; }

ul,
ol {
  padding-left: 20px;
  list-style: none; }

ul > li > ul,
ul > li > ol,
ol > li > ol,
ol > li > ul {
  margin: 0; }

dt {
  font-weight: bold; }

dd {
  margin-left: 0; }

hr {
  box-sizing: border-box;
  height: 0;
  overflow: visible;
  text-align: inherit;
  margin: 0 0 12px 0;
  border-top: 1px solid rgba(90, 90, 90, 0.5);
  width: 100%; }

* + hr {
  margin-top: 12px; }

address {
  font-style: normal; }

blockquote {
  margin: 0 0 12px 0;
  font-size: 14px;
  line-height: 1.5;
  font-style: italic;
  color: #333; }

* + blockquote {
  margin-top: 12px; }

blockquote p:last-of-type {
  margin-bottom: 0; }

blockquote footer {
  margin-top: 12px;
  font-size: 14px;
  line-height: 1.5;
  color: #666; }

pre {
  font: 9.33333px Consolas, monaco, monospace;
  color: #5A5A5A;
  tab-size: 4;
  overflow: auto;
  padding: 10px;
  border: 1px solid #e5e5e5;
  background: #fff; }

pre code {
  font-family: Consolas, monaco, monospace; }

::-moz-selection {
  background: #39f;
  color: #FFFFFF;
  text-shadow: none; }

::selection {
  background: #39f;
  color: #FFFFFF;
  text-shadow: none; }

details,
main {
  display: block; }

summary {
  display: list-item; }

template {
  display: none; }

iframe {
  border: 0; }

a,
area,
button,
input,
label,
select,
summary,
textarea {
  touch-action: manipulation; }

[hidden] {
  display: none !important; }

.aligncenter {
  text-align: center; }

table {
  border-collapse: collapse;
  width: 100%;
  border-spacing: 0;
  margin-bottom: 12px;
  background: transparent;
  color: #5A5A5A; }

* + table {
  margin-top: 12px; }

table caption {
  text-align: left; }

table td,
table th {
  text-align: left;
  border: 1px solid rgba(0, 57, 138, 0.3);
  border-width: 0 0 1px;
  padding: 16px;
  vertical-align: top; }

.table-shrink {
  width: 1px; }

.table-expand {
  min-width: 150px; }

button,
input[type="button"],
input[type="submit"],
.button {
  margin: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  user-select: none;
  border-radius: 0;
  overflow: visible;
  font: inherit;
  text-transform: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 14px 36px;
  font-family: "Overpass", sans-serif;
  font-size: 16px;
  min-height: 50px;
  text-decoration: none;
  background: #D6A33A;
  color: #ffffff;
  min-width: 200px;
  cursor: pointer; }

.button:not(:disabled) {
  cursor: pointer; }

.button::-moz-focus-inner {
  border: 0;
  padding: 0; }

.button:hover {
  text-decoration: none;
  background: #D6A33A;
  color: #ffffff; }

.button:focus {
  outline: none;
  background: #D6A33A;
  color: #ffffff; }

.button:active,
.button.is-active {
  background: #D6A33A;
  color: #ffffff; }

.button:disabled {
  pointer-events: none;
  opacity: 0.45; }

.button-secondary {
  background-color: #00398A; }

input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit; }

button,
select {
  text-transform: none;
  cursor: pointer; }

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto; }

[type="radio"]:not(:disabled),
[type="checkbox"]:not(:disabled) {
  cursor: pointer; }

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button; }

textarea {
  overflow: auto;
  resize: vertical; }

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0; }

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: 14px;
  line-height: 1.4;
  color: inherit;
  white-space: normal; }

progress {
  vertical-align: baseline; }

output {
  display: inline-block; }

summary {
  display: list-item;
  cursor: pointer; }

::-webkit-datetime-edit,
::-webkit-inner-spin-button {
  height: auto; }

input:not([type]),
input[type=date],
input[type=datetime-local],
input[type=email],
input[type=file],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type="month"],
input[type=week],
input[type=text],
input[type=time],
input[type=url],
textarea,
select {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: 6px 14px;
  min-height: 50px;
  background: transparent;
  border: 1px solid #5A5A5A;
  color: #5A5A5A;
  box-shadow: 0 0 0 0 transparent inset;
  border-radius: 0; }

::placeholder {
  color: #5A5A5A;
  opacity: 1; }

input:not([type]):focus,
input[type=date]:focus,
input[type=datetime-local]:focus,
input[type=email]:focus,
input[type=file]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type="month"]:focus,
input[type=week]:focus,
input[type=text]:focus,
input[type=time]:focus,
input[type=url]:focus,
textarea:focus,
select:focus {
  background: transparent;
  color: #5A5A5A; }

input:not([type])[disabled],
input[type=date][disabled],
input[type=datetime-local][disabled],
input[type=email][disabled],
input[type=file][disabled],
input[type=number][disabled],
input[type=password][disabled],
input[type=search][disabled],
input[type=tel][disabled],
input[type="month"][disabled],
input[type=week][disabled],
input[type=text][disabled],
input[type=time][disabled],
input[type=url][disabled],
textarea:disabled,
select:disabled {
  pointer-events: none;
  opacity: .45;
  background: transparent;
  color: #5A5A5A; }

select:not([multiple]):not([size]) {
  padding-right: 60px;
  padding-left: 8px;
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="14" height="14" viewBox="0 0 451.847 451.847" xml:space="preserve" fill="black"><path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/></svg>');
  background-repeat: no-repeat;
  background-position: 96% 50%; }

select:not([multiple]):not([size])::-ms-expand {
  display: none; }

select:not([multiple]):not([size]) option {
  color: #5A5A5A; }

select:not([multiple]):not([size]):disabled {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="14" height="14" viewBox="0 0 451.847 451.847" xml:space="preserve" fill="black"><path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/></svg>'); }

input[type="radio"] {
  border: none;
  display: inline-block;
  height: 16px;
  width: 16px;
  overflow: hidden;
  margin-top: -4px;
  vertical-align: middle;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 50% 50%; }

input[type="checkbox"] {
  border: 1px solid #3E3E3E;
  display: inline-block;
  height: 16px;
  width: 16px;
  min-width: 16px;
  overflow: hidden;
  margin-top: -4px;
  vertical-align: middle;
  background-color: transparent;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 50% 50%; }

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none; }

input[type="radio"] {
  border-radius: 50%; }

input[type="radio"]:checked {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M10 8c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z"></path></svg>'); }

input[type="checkbox"]:checked {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" stroke="black"><path fill="none" stroke="inherit" stroke-width="1.8824" stroke-miterlimit="50" stroke-linecap="butt" stroke-linejoin="miter" d="M20.028 2.353l-12.028 12.028-6.381-6.381"></path></svg>'); }

input[type="checkbox"]:indeterminate {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M10 8c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z"></path></svg>'); }

input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none; }

input[type="radio"]:checked,
input[type="checkbox"]:checked,
input[type="checkbox"]:indeterminate {
  background-color: transparent; }

input[type="radio"]:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: transparent; }

input[type="radio"]:disabled:checked {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M10 8c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z"></path></svg>'); }

input[type="checkbox"]:disabled:checked {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" stroke="black"><path fill="none" stroke="inherit" stroke-width="1.8824" stroke-miterlimit="50" stroke-linecap="butt" stroke-linejoin="miter" d="M20.028 2.353l-12.028 12.028-6.381-6.381"></path></svg>'); }

input[type="checkbox"]:disabled:indeterminate {
  background-image: url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M3 8h10v1h-10v-1z"></path></svg>'); }

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  background-color: transparent; }

input[type="range"] {
  box-sizing: border-box;
  margin: 0;
  vertical-align: middle;
  max-width: 100%;
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  padding: 0; }

input[type="range"]:focus {
  outline: none; }

input[type="range"]::-moz-focus-outer {
  border: none; }

input[type="range"]::-ms-track {
  height: 15px;
  background: transparent;
  border-color: transparent;
  color: transparent; }

input[type="range"]:not(:disabled)::-webkit-slider-thumb {
  cursor: pointer; }

input[type="range"]:not(:disabled)::-moz-range-thumb {
  cursor: pointer; }

input[type="range"]:not(:disabled)::-ms-thumb {
  cursor: pointer; }

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: -8px;
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #5A5A5A; }

input[type="range"]::-moz-range-thumb {
  border: none;
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #5A5A5A; }

input[type="range"]::-ms-thumb {
  margin-top: 0; }

input[type="range"]::-ms-thumb {
  border: none;
  height: 15px;
  width: 15px;
  border-radius: 500px;
  background: #5A5A5A; }

input[type="range"]::-ms-tooltip {
  display: none; }

input[type="range"]::-webkit-slider-runnable-track {
  height: 3px;
  background: #EFF0F2; }

input[type="range"]:focus::-webkit-slider-runnable-track,
input[type="range"]:active::-webkit-slider-runnable-track {
  background: #EFF0F2; }

input[type="range"]::-moz-range-track {
  height: 3px;
  background: #EFF0F2; }

input[type="range"]:focus::-moz-range-track {
  background: #EFF0F2; }

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  height: 3px;
  background: #EFF0F2; }

input[type="range"]:focus::-ms-fill-lower,
input[type="range"]:focus::-ms-fill-upper {
  background: #EFF0F2; }

label {
  margin: 0.8rem 0;
  display: inline-block; }

label.is-invalid {
  color: #f0506e; }

label.is-valid {
  color: #32d296; }

input.is-invalid,
input.is-invalid:focus {
  color: #f0506e;
  border-color: #f0506e; }

input.is-valid,
input.is-valid:focus {
  color: #32d296;
  border-color: #32d296; }

.form-control {
  margin-bottom: 12px; }

.form-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: 50px;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: #3E3E3E; }

.form-icon:hover {
  color: #5A5A5A; }

.form-icon:not(a):not(button):not(input) {
  pointer-events: none; }

.form-icon:not(.form-icon-flip) ~ input {
  padding-left: 50px !important; }

.form-icon-flip {
  right: 0;
  left: auto; }

.form-icon-flip ~ input {
  padding-right: 50px !important; }

.get-in-touch span.asterix {
  color: #EB5757; }

.get-in-touch img.ajax-loader {
  margin-right: -16px; }

.get-in-touch input.wpcf7-not-valid {
  border: 1px solid #EB5757; }

.get-in-touch span.wpcf7-not-valid-tip {
  padding: 6px 0;
  color: #EB5757;
  font-size: 14px; }

.get-in-touch div.wpcf7-validation-errors {
  border: none;
  color: #EB5757;
  text-align: center; }

.get-in-touch div.wpcf7-mail-sent-ok {
  border: none;
  color: #32d296; }

.get-in-touch div.wpcf7-validation-errors,
.get-in-touch div.wpcf7-mail-sent-ok {
  margin: 4px auto;
  width: 300px; }

.grecaptcha-badge {
  position: absolute;
  z-index: -100; }

.container {
  max-width: 1200px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto; }

.container .container {
  padding-left: 0;
  padding-right: 0; }

.container-expand {
  max-width: none; }

.section-title,
#reply-title {
  margin-bottom: 2rem;
  text-align: center;
  overflow: hidden; }
  .section-title p, .section-title h1, .section-title h2, .section-title h3, .section-title h4, .section-title h5, .section-title h6,
  #reply-title p,
  #reply-title h1,
  #reply-title h2,
  #reply-title h3,
  #reply-title h4,
  #reply-title h5,
  #reply-title h6 {
    display: inline-block;
    position: relative;
    font-family: "Montserrat", sans-serif;
    font-size: 36px;
    color: #00398A;
    font-weight: 600;
    width: auto; }
    .section-title p::before, .section-title p::after, .section-title h1::before, .section-title h1::after, .section-title h2::before, .section-title h2::after, .section-title h3::before, .section-title h3::after, .section-title h4::before, .section-title h4::after, .section-title h5::before, .section-title h5::after, .section-title h6::before, .section-title h6::after,
    #reply-title p::before,
    #reply-title p::after,
    #reply-title h1::before,
    #reply-title h1::after,
    #reply-title h2::before,
    #reply-title h2::after,
    #reply-title h3::before,
    #reply-title h3::after,
    #reply-title h4::before,
    #reply-title h4::after,
    #reply-title h5::before,
    #reply-title h5::after,
    #reply-title h6::before,
    #reply-title h6::after {
      content: "";
      position: absolute;
      width: 100px;
      height: 1px;
      background-color: #00398A; }
    .section-title p::before, .section-title h1::before, .section-title h2::before, .section-title h3::before, .section-title h4::before, .section-title h5::before, .section-title h6::before,
    #reply-title p::before,
    #reply-title h1::before,
    #reply-title h2::before,
    #reply-title h3::before,
    #reply-title h4::before,
    #reply-title h5::before,
    #reply-title h6::before {
      left: -120px;
      top: 50%; }
    .section-title p::after, .section-title h1::after, .section-title h2::after, .section-title h3::after, .section-title h4::after, .section-title h5::after, .section-title h6::after,
    #reply-title p::after,
    #reply-title h1::after,
    #reply-title h2::after,
    #reply-title h3::after,
    #reply-title h4::after,
    #reply-title h5::after,
    #reply-title h6::after {
      right: -120px;
      top: 50%; }

@media screen and (max-width: 576px) {
  .section-title p, .section-title h1, .section-title h2, .section-title h3, .section-title h4, .section-title h5, .section-title h6,
  #reply-title p,
  #reply-title h1,
  #reply-title h2,
  #reply-title h3,
  #reply-title h4,
  #reply-title h5,
  #reply-title h6 {
    max-width: 270px; }
    .section-title p::before, .section-title p::after, .section-title h1::before, .section-title h1::after, .section-title h2::before, .section-title h2::after, .section-title h3::before, .section-title h3::after, .section-title h4::before, .section-title h4::after, .section-title h5::before, .section-title h5::after, .section-title h6::before, .section-title h6::after,
    #reply-title p::before,
    #reply-title p::after,
    #reply-title h1::before,
    #reply-title h1::after,
    #reply-title h2::before,
    #reply-title h2::after,
    #reply-title h3::before,
    #reply-title h3::after,
    #reply-title h4::before,
    #reply-title h4::after,
    #reply-title h5::before,
    #reply-title h5::after,
    #reply-title h6::before,
    #reply-title h6::after {
      width: 40px; }
    .section-title p::before, .section-title h1::before, .section-title h2::before, .section-title h3::before, .section-title h4::before, .section-title h5::before, .section-title h6::before,
    #reply-title p::before,
    #reply-title h1::before,
    #reply-title h2::before,
    #reply-title h3::before,
    #reply-title h4::before,
    #reply-title h5::before,
    #reply-title h6::before {
      left: -50px; }
    .section-title p::after, .section-title h1::after, .section-title h2::after, .section-title h3::after, .section-title h4::after, .section-title h5::after, .section-title h6::after,
    #reply-title p::after,
    #reply-title h1::after,
    #reply-title h2::after,
    #reply-title h3::after,
    #reply-title h4::after,
    #reply-title h5::after,
    #reply-title h6::after {
      right: -50px; } }

@media screen and (max-width: 320px) {
  .section-title p, .section-title h1, .section-title h2, .section-title h3, .section-title h4, .section-title h5, .section-title h6 {
    max-width: 160px;
    font-size: 1.6rem; } }

.separator {
  height: 40px; }

header {
  background-color: #00398A; }
  header * {
    color: #FFFFFF; }
  header .head_content {
    position: relative;
    z-index: 2;
    color: #FFFFFF; }
    header .head_content .item-top {
      display: flex;
      align-items: center;
      justify-content: space-between;
      padding: 1rem 0; }
      header .head_content .item-top .head_logo a {
        display: inline-block; }
      header .head_content .item-top .head_logo img {
        width: 300px;
        height: 100%; }
      header .head_content .item-top .header-weather .simple-weather {
        width: 100%; }
        header .head_content .item-top .header-weather .simple-weather .simple-weather-widget {
          padding: 0 !important; }
          header .head_content .item-top .header-weather .simple-weather .simple-weather-widget .simple-weather-table {
            display: flex;
            align-items: center;
            justify-content: space-between;
            margin: 6px 0; }
            header .head_content .item-top .header-weather .simple-weather .simple-weather-widget .simple-weather-table > div {
              display: inline-block;
              width: auto; }
              header .head_content .item-top .header-weather .simple-weather .simple-weather-widget .simple-weather-table > div div {
                margin: 0;
                padding: 2px; }
              header .head_content .item-top .header-weather .simple-weather .simple-weather-widget .simple-weather-table > div i {
                margin: 0;
                color: #D4AA5C; }
      header .head_content .item-top .header-contact-no .contact-info p.phone-no {
        font-size: 28px;
        line-height: 35px; }
        header .head_content .item-top .header-contact-no .contact-info p.phone-no a {
          color: #D4AA5C; }
        header .head_content .item-top .header-contact-no .contact-info p.phone-no a:hover,
        header .head_content .item-top .header-contact-no .contact-info p.phone-no a:active,
        header .head_content .item-top .header-contact-no .contact-info p.phone-no a:focus {
          color: #D4AA5C; }
    header .head_content hr {
      border-top: 4px double #FFFFFF; }
    header .head_content .main_menu ul {
      padding: 0; }
    header .head_content .main_menu a:not([href]) {
      cursor: default; }
      header .head_content .main_menu a:not([href]):hover, header .head_content .main_menu a:not([href]):focus, header .head_content .main_menu a:not([href]):active {
        cursor: default; }
    header .head_content .main_menu .main-menu-layout {
      display: flex;
      align-items: center;
      justify-content: space-between;
      height: 60px; }
      header .head_content .main_menu .main-menu-layout > ul {
        display: flex;
        align-items: center;
        width: 100%;
        height: 100%;
        margin: 0; }
        header .head_content .main_menu .main-menu-layout > ul > li {
          position: relative;
          margin-right: 20px;
          height: 100%;
          display: flex;
          align-items: center; }
          header .head_content .main_menu .main-menu-layout > ul > li > a {
            display: inline-block;
            color: #FFFFFF;
            font-size: 15px;
            text-transform: uppercase; }
            header .head_content .main_menu .main-menu-layout > ul > li > a:not(:last-child) {
              margin-right: 30px; }
          header .head_content .main_menu .main-menu-layout > ul > li .sub-menu {
            position: absolute;
            display: none;
            top: 100%;
            min-width: 250px;
            background-color: #f9f7f7; }
            header .head_content .main_menu .main-menu-layout > ul > li .sub-menu li {
              position: relative; }
              header .head_content .main_menu .main-menu-layout > ul > li .sub-menu li a {
                font-size: 16px;
                width: 100%;
                display: inline-block;
                padding: 10px 16px;
                color: #5A5A5A; }
                header .head_content .main_menu .main-menu-layout > ul > li .sub-menu li a:hover, header .head_content .main_menu .main-menu-layout > ul > li .sub-menu li a:focus, header .head_content .main_menu .main-menu-layout > ul > li .sub-menu li a:active {
                  background-color: #eaeaea; }
              header .head_content .main_menu .main-menu-layout > ul > li .sub-menu li .sub-menu {
                left: 100%;
                top: 0; }
          header .head_content .main_menu .main-menu-layout > ul > li .icon-arrow-down {
            position: absolute;
            right: 0px;
            top: 50%;
            transform: translateY(-50%);
            font-size: 8px; }
        header .head_content .main_menu .main-menu-layout > ul > li:hover:after {
          content: "";
          display: inline-block;
          height: 0px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #D4AA5C; }
        header .head_content .main_menu .main-menu-layout > ul > li:hover:hover:after, header .head_content .main_menu .main-menu-layout > ul > li:hover:focus:after, header .head_content .main_menu .main-menu-layout > ul > li:hover:active:after {
          height: 2px; }
        header .head_content .main_menu .main-menu-layout > ul > li:hover > a,
        header .head_content .main_menu .main-menu-layout > ul > li:hover > .icon-arrow-down {
          color: #D4AA5C; }
        header .head_content .main_menu .main-menu-layout > ul > li.current-menu-parent:after,
        header .head_content .main_menu .main-menu-layout > ul > li.current-menu-item:after,
        header .head_content .main_menu .main-menu-layout > ul > li.current-menu-ancestor:after {
          content: "";
          display: inline-block;
          height: 2px;
          width: 100%;
          position: absolute;
          bottom: 0;
          left: 0;
          background-color: #D4AA5C; }
        header .head_content .main_menu .main-menu-layout > ul > li.current-menu-parent > a,
        header .head_content .main_menu .main-menu-layout > ul > li.current-menu-parent .icon-arrow-down,
        header .head_content .main_menu .main-menu-layout > ul > li.current-menu-item > a,
        header .head_content .main_menu .main-menu-layout > ul > li.current-menu-item .icon-arrow-down,
        header .head_content .main_menu .main-menu-layout > ul > li.current-menu-ancestor > a,
        header .head_content .main_menu .main-menu-layout > ul > li.current-menu-ancestor .icon-arrow-down {
          color: #D4AA5C; }
      header .head_content .main_menu .main-menu-layout #searchform {
        position: relative; }
        header .head_content .main_menu .main-menu-layout #searchform input.search {
          color: #FFFFFF;
          border: 1px solid #FFFFFF;
          min-height: 40px;
          padding-left: 26px; }
          header .head_content .main_menu .main-menu-layout #searchform input.search::placeholder {
            color: #FFFFFF;
            transition: 0.2s; }
          header .head_content .main_menu .main-menu-layout #searchform input.search:hover, header .head_content .main_menu .main-menu-layout #searchform input.search:active, header .head_content .main_menu .main-menu-layout #searchform input.search:focus {
            padding-right: 26px;
            padding-left: 14px; }
          header .head_content .main_menu .main-menu-layout #searchform input.search:hover::placeholder, header .head_content .main_menu .main-menu-layout #searchform input.search:active::placeholder, header .head_content .main_menu .main-menu-layout #searchform input.search:focus::placeholder {
            color: transparent;
            transition: 0.2s; }
        header .head_content .main_menu .main-menu-layout #searchform .icon-search {
          position: absolute;
          left: 8px;
          top: 14px; }
        header .head_content .main_menu .main-menu-layout #searchform:hover .icon-search,
        header .head_content .main_menu .main-menu-layout #searchform:active .icon-search,
        header .head_content .main_menu .main-menu-layout #searchform:visited .icon-search,
        header .head_content .main_menu .main-menu-layout #searchform:focus-within .icon-search {
          left: auto;
          right: 8px; }
    header .head_content .mobile-btn {
      display: none;
      justify-content: space-between;
      align-items: center;
      padding: 1.2rem 0.6rem;
      cursor: pointer;
      font-size: 2rem; }
      header .head_content .mobile-btn p {
        margin: 0; }
    header .head_content .mobile-menu {
      display: none; }

@media screen and (max-width: 1000px) {
  header .head_content .item-top {
    flex-direction: column; }
    header .head_content .item-top .head_logo {
      padding: 1rem; }
    header .head_content .item-top .header-weather {
      display: none; }
    header .head_content .item-top .header-contact-no {
      text-align: center;
      padding-top: 1rem;
      width: 100%;
      border-top: 4px double #fff; }
  header .head_content hr {
    display: none; }
  header .head_content .item-nav {
    background-color: #3BB7FF; }
    header .head_content .item-nav .mobile-btn {
      display: flex; }
      header .head_content .item-nav .mobile-btn .burger-btn {
        display: inline-block; }
        header .head_content .item-nav .mobile-btn .burger-btn .bar1,
        header .head_content .item-nav .mobile-btn .burger-btn .bar2,
        header .head_content .item-nav .mobile-btn .burger-btn .bar3 {
          width: 30px;
          height: 4px;
          background-color: #FFFFFF;
          margin: 4px 0;
          transition: 0.3s; }
        header .head_content .item-nav .mobile-btn .burger-btn.change .bar1 {
          -webkit-transform: rotate(-45deg) translate(-4px, 4px);
          transform: rotate(-45deg) translate(-4px, 4px); }
        header .head_content .item-nav .mobile-btn .burger-btn.change .bar2 {
          opacity: 0; }
        header .head_content .item-nav .mobile-btn .burger-btn.change .bar3 {
          -webkit-transform: rotate(45deg) translate(-8px, -8px);
          transform: rotate(45deg) translate(-8px, -8px); }
    header .head_content .item-nav .main_menu {
      display: none; }
    header .head_content .item-nav .mobile-menu {
      display: none; }
      header .head_content .item-nav .mobile-menu > ul {
        flex-direction: column;
        padding: 0; }
        header .head_content .item-nav .mobile-menu > ul li {
          margin: 0; }
          header .head_content .item-nav .mobile-menu > ul li a {
            margin: 0;
            padding: 8px;
            width: 100%;
            display: inline-block;
            font-size: 16px; }
          header .head_content .item-nav .mobile-menu > ul li .sub-menu {
            padding: 0;
            display: none;
            padding-left: 24px; }
        header .head_content .item-nav .mobile-menu > ul > li > a {
          font-weight: bold;
          font-size: 18px; } }

footer {
  background-color: #00398A;
  padding: 3rem 0 1rem; }
  footer * {
    color: #FFFFFF; }
  footer .footer-layout {
    display: flex;
    justify-content: space-between;
    padding: 1rem 0; }
    footer .footer-layout .footer-item {
      max-width: 20%; }
      footer .footer-layout .footer-item * {
        font-size: 16px;
        font-family: "Overpass", sans-serif; }
      footer .footer-layout .footer-item .h1 {
        margin-bottom: 1rem;
        font-family: "Montserrat", sans-serif;
        font-size: 16px;
        font-weight: 600;
        color: #D6A33A; }
      footer .footer-layout .footer-item p {
        margin-bottom: 0.8rem; }
      footer .footer-layout .footer-item .item-phone,
      footer .footer-layout .footer-item .item-fax,
      footer .footer-layout .footer-item .item-address {
        position: relative;
        padding-left: 20px;
        margin-bottom: 22px; }
        footer .footer-layout .footer-item .item-phone:before,
        footer .footer-layout .footer-item .item-fax:before,
        footer .footer-layout .footer-item .item-address:before {
          font-family: 'icomoon' !important;
          position: absolute;
          left: 0;
          top: 0; }
      footer .footer-layout .footer-item .item-phone:before {
        content: "\e909";
        color: #d6a33a; }
      footer .footer-layout .footer-item .item-fax:before {
        content: "\e904";
        color: #d6a33a; }
      footer .footer-layout .footer-item .item-address:before {
        content: "\e907";
        color: #d6a33a; }
      footer .footer-layout .footer-item .text-subscribe {
        font-size: 12px; }
      footer .footer-layout .footer-item #subscribe_form input {
        border-color: #FFFFFF;
        min-height: 34px;
        font-size: 14px;
        padding: 8px;
        color: #FFFFFF;
        font-weight: normal; }
        footer .footer-layout .footer-item #subscribe_form input::placeholder {
          color: #FFFFFF;
          font-size: 12px; }
        footer .footer-layout .footer-item #subscribe_form input#email {
          position: relative; }
      footer .footer-layout .footer-item #subscribe_form #btnsubscribe {
        min-height: 34px;
        width: 100%;
        min-width: auto;
        border: none;
        border-radius: 0;
        background-color: #D4AA5C; }
        footer .footer-layout .footer-item #subscribe_form #btnsubscribe:hover, footer .footer-layout .footer-item #subscribe_form #btnsubscribe:active, footer .footer-layout .footer-item #subscribe_form #btnsubscribe:focus {
          background-color: #3BB7FF; }
    footer .footer-layout #menu-footer-one {
      padding: 0; }
      footer .footer-layout #menu-footer-one li {
        position: relative;
        padding-left: 18px;
        margin-bottom: 6px; }
        footer .footer-layout #menu-footer-one li span {
          position: absolute;
          display: inline-block;
          transform: rotate(-90deg);
          font-size: 6px;
          top: 6px;
          left: 0;
          color: #D4AA5C; }
  footer hr {
    border-top: 4px double #FFFFFF; }
  footer .footer-copyright .copyright {
    display: flex;
    align-items: center;
    text-align: center;
    padding: 1rem 0; }
    footer .footer-copyright .copyright picture {
      max-height: 26px; }
      footer .footer-copyright .copyright picture img {
        height: 26px; }
    footer .footer-copyright .copyright p {
      margin: 0 1rem;
      font-size: 12px; }

@media screen and (max-width: 768px) {
  footer .footer-layout {
    flex-wrap: wrap; }
    footer .footer-layout .footer-item {
      max-width: 50%;
      width: 50%;
      padding: 1rem 0; } }

@media screen and (max-width: 576px) {
  footer .footer-layout .footer-item {
    max-width: 100%;
    width: 100%; }
    footer .footer-layout .footer-item:not(:last-child) {
      border-bottom: 1px solid #FFFFFF; } }

#donation-form,
#contact-us {
  width: 700px;
  max-width: 100%;
  padding: 2rem;
  box-sizing: border-box !important; }
  #donation-form .gsection_description,
  #contact-us .gsection_description {
    margin: 1rem 0;
    padding: 0;
    text-align: center;
    font-size: 14px; }
  #donation-form .gform_wrapper,
  #contact-us .gform_wrapper {
    max-width: none; }
    #donation-form .gform_wrapper div.validation_error,
    #contact-us .gform_wrapper div.validation_error {
      color: #EB5757; }
    #donation-form .gform_wrapper .gsection,
    #contact-us .gform_wrapper .gsection {
      border-bottom: none;
      margin: 2rem 0; }
      #donation-form .gform_wrapper .gsection .gsection_title,
      #contact-us .gform_wrapper .gsection .gsection_title {
        text-align: center;
        width: auto; }
    #donation-form .gform_wrapper .top_label .gfield_label,
    #donation-form .gform_wrapper .ginput_complex label,
    #contact-us .gform_wrapper .top_label .gfield_label,
    #contact-us .gform_wrapper .ginput_complex label {
      font-weight: normal;
      margin-bottom: 1rem; }
    #donation-form .gform_wrapper li.gfield,
    #contact-us .gform_wrapper li.gfield {
      margin-bottom: 18px; }
    #donation-form .gform_wrapper li.gfield.gfield_error,
    #contact-us .gform_wrapper li.gfield.gfield_error {
      background-color: transparent;
      padding: 0 !important;
      border-top: none;
      border-bottom: none; }
      #donation-form .gform_wrapper li.gfield.gfield_error label,
      #contact-us .gform_wrapper li.gfield.gfield_error label {
        color: #5A5A5A; }
      #donation-form .gform_wrapper li.gfield.gfield_error input,
      #donation-form .gform_wrapper li.gfield.gfield_error textarea,
      #contact-us .gform_wrapper li.gfield.gfield_error input,
      #contact-us .gform_wrapper li.gfield.gfield_error textarea {
        border: 1px solid #EB5757; }
      #donation-form .gform_wrapper li.gfield.gfield_error.gfield_contains_required,
      #contact-us .gform_wrapper li.gfield.gfield_error.gfield_contains_required {
        padding-left: 0 !important; }
      #donation-form .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning,
      #contact-us .gform_wrapper li.gfield.gfield_error.gfield_contains_required.gfield_creditcard_warning {
        background-color: transparent;
        padding: 0 !important;
        border-top: none;
        border-bottom: none; }
      #donation-form .gform_wrapper li.gfield.gfield_error .ginput_complex .ginput_left,
      #donation-form .gform_wrapper li.gfield.gfield_error .ginput_complex .ginput_right,
      #contact-us .gform_wrapper li.gfield.gfield_error .ginput_complex .ginput_left,
      #contact-us .gform_wrapper li.gfield.gfield_error .ginput_complex .ginput_right {
        width: 46%; }
    #donation-form .gform_wrapper .validation_message,
    #donation-form .gform_wrapper .gfield_required,
    #contact-us .gform_wrapper .validation_message,
    #contact-us .gform_wrapper .gfield_required {
      color: #EB5757;
      font-size: 14px;
      font-weight: normal;
      padding: 0 !important; }
    #donation-form .gform_wrapper ul.gfield_radio,
    #contact-us .gform_wrapper ul.gfield_radio {
      display: flex;
      flex-wrap: wrap;
      border-bottom: 1px solid #ccc;
      padding-bottom: 1rem; }
      #donation-form .gform_wrapper ul.gfield_radio li,
      #contact-us .gform_wrapper ul.gfield_radio li {
        min-width: 180px; }
        #donation-form .gform_wrapper ul.gfield_radio li input,
        #contact-us .gform_wrapper ul.gfield_radio li input {
          height: auto !important;
          width: 16px !important; }
    #donation-form .gform_wrapper .top_label .gfield_error,
    #contact-us .gform_wrapper .top_label .gfield_error {
      width: 100%; }
      #donation-form .gform_wrapper .top_label .gfield_error .ginput_container,
      #contact-us .gform_wrapper .top_label .gfield_error .ginput_container {
        max-width: none; }
      #donation-form .gform_wrapper .top_label .gfield_error.gf_left_half, #donation-form .gform_wrapper .top_label .gfield_error.gf_right_half,
      #contact-us .gform_wrapper .top_label .gfield_error.gf_left_half,
      #contact-us .gform_wrapper .top_label .gfield_error.gf_right_half {
        width: 46% !important; }
    #donation-form .gform_wrapper input:not([type=submit]),
    #donation-form .gform_wrapper select,
    #contact-us .gform_wrapper input:not([type=submit]),
    #contact-us .gform_wrapper select {
      width: 100% !important; }
    #donation-form .gform_wrapper .gform_footer,
    #contact-us .gform_wrapper .gform_footer {
      text-align: center; }
  #donation-form .section-title .gsection_title,
  #contact-us .section-title .gsection_title {
    font-size: 2.4rem; }

[class^="icon-"],
[class*=" icon-"] {
  speak: none;
  font-style: normal;
  font-weight: normal;
  font-variant: normal;
  text-transform: none;
  line-height: 1;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale; }

svg {
  max-height: 100%;
  max-width: 100%; }

svg[class^="icon-"],
svg[class*=" icon-"] {
  display: inline-block;
  width: 1em;
  height: 1em;
  stroke-width: 0;
  stroke: currentColor;
  fill: currentColor; }

.icon-type:before {
  border: 1px solid #D4AA5C;
  color: #D4AA5C;
  border-radius: 50%; }

#fancybox-close {
  top: 12px !important;
  right: 12px !important;
  background: none !important;
  font-size: 18px; }
  #fancybox-close:before {
    position: absolute;
    content: "\e905";
    font-family: 'icomoon'; }

.pagination {
  text-align: center;
  margin-bottom: 20px; }
  .pagination .page-numbers {
    color: #5A5A5A;
    margin: 8px; }
    .pagination .page-numbers:hover, .pagination .page-numbers:active, .pagination .page-numbers:focus {
      color: #D4AA5C; }
    .pagination .page-numbers.current {
      color: #D4AA5C; }

.block-pagination {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 100%; }
  .block-pagination .by_number {
    display: flex;
    flex-wrap: wrap; }
  .block-pagination a {
    margin: 8px;
    color: #5A5A5A; }
    .block-pagination a:hover, .block-pagination a:active, .block-pagination a:focus {
      color: #D4AA5C; }
    .block-pagination a.active {
      color: #D4AA5C;
      user-select: none;
      cursor: default;
      pointer-events: none; }

section.banner-home {
  margin-bottom: 1rem; }
  section.banner-home .container {
    padding: 0;
    max-width: none; }
    section.banner-home .container .banner-home-layout {
      display: flex;
      align-items: stretch; }
      section.banner-home .container .banner-home-layout .banner-item {
        display: flex;
        max-width: 50%;
        flex-grow: 1;
        min-height: 380px;
        background-repeat: no-repeat;
        background-size: cover;
        background-position: center center; }
        section.banner-home .container .banner-home-layout .banner-item .item-layout {
          display: flex;
          flex-direction: column;
          align-items: center;
          justify-content: center;
          width: 100%;
          background-color: rgba(0, 0, 0, 0.4);
          padding: 1rem;
          outline: 1px solid #FFFFFF;
          outline-offset: -1rem;
          transition: 0.2s; }
          section.banner-home .container .banner-home-layout .banner-item .item-layout:hover {
            background-color: rgba(0, 0, 0, 0);
            transition: 0.2s; }
          section.banner-home .container .banner-home-layout .banner-item .item-layout .item-title {
            font-family: "Montserrat", sans-serif;
            font-size: 36px;
            color: #FFFFFF;
            font-weight: 600;
            text-align: center; }
          section.banner-home .container .banner-home-layout .banner-item .item-layout .item-subtitle {
            font-size: 18px;
            color: #FFFFFF;
            margin-bottom: 14px;
            text-align: center; }
          section.banner-home .container .banner-home-layout .banner-item .item-layout .item-action:hover, section.banner-home .container .banner-home-layout .banner-item .item-layout .item-action:active, section.banner-home .container .banner-home-layout .banner-item .item-layout .item-action:focus {
            background-color: #3BB7FF; }
          section.banner-home .container .banner-home-layout .banner-item .item-layout .item-details {
            display: flex;
            justify-content: space-around;
            align-items: center;
            text-align: center;
            padding: 1rem;
            width: 100%; }
            section.banner-home .container .banner-home-layout .banner-item .item-layout .item-details .details-wrap {
              padding: 8px; }
              section.banner-home .container .banner-home-layout .banner-item .item-layout .item-details .details-wrap span {
                font-size: 4rem; }
              section.banner-home .container .banner-home-layout .banner-item .item-layout .item-details .details-wrap .item-text {
                font-size: 1.2rem;
                color: #FFFFFF;
                font-weight: 600;
                text-transform: uppercase; }

@media screen and (max-width: 1000px) {
  section.banner-home .container {
    padding: 0 1rem; }
    section.banner-home .container .banner-home-layout {
      flex-direction: column; }
      section.banner-home .container .banner-home-layout .banner-item {
        max-width: 100%;
        flex-grow: 1;
        width: 100%;
        margin-bottom: 1rem;
        min-height: 320px; }
        section.banner-home .container .banner-home-layout .banner-item .item-layout .item-details .details-wrap span {
          font-size: 2.4rem; }
        section.banner-home .container .banner-home-layout .banner-item .item-layout .item-details .details-wrap .item-text {
          font-size: 14px; } }

@media screen and (min-width: 2000px) {
  section.banner-home .container .banner-home-layout .banner-item {
    min-height: 580px; } }

section.history .history-layout p {
  margin: 0; }

section.history .history-layout #tabs {
  margin-bottom: 4rem; }
  section.history .history-layout #tabs .tabs-header {
    display: flex;
    align-items: center;
    justify-content: space-around;
    background: transparent;
    padding: 1rem; }
    section.history .history-layout #tabs .tabs-header > li {
      margin: 0; }
      section.history .history-layout #tabs .tabs-header > li a {
        color: #3E3E3E;
        text-transform: capitalize;
        font-size: 24px;
        font-family: "Montserrat", sans-serif;
        font-weight: 600; }
        section.history .history-layout #tabs .tabs-header > li a:hover, section.history .history-layout #tabs .tabs-header > li a:active, section.history .history-layout #tabs .tabs-header > li a:focus {
          color: #D4AA5C;
          border: none;
          outline: none;
          text-decoration: underline; }
      section.history .history-layout #tabs .tabs-header > li.ui-tabs-active {
        border: none; }
        section.history .history-layout #tabs .tabs-header > li.ui-tabs-active a {
          color: #023278;
          cursor: default;
          border: none;
          outline: none;
          text-decoration: underline; }
  section.history .history-layout #tabs .tabs-list {
    position: relative;
    z-index: 1;
    background-image: url(/wp-content/themes/steeles-new/img/bg/history.png);
    background-position: center center;
    background-repeat: no-repeat;
    background-size: cover;
    padding: 4rem 0; }
    section.history .history-layout #tabs .tabs-list:before {
      position: absolute;
      z-index: -1;
      content: "";
      left: 0;
      width: 100%;
      top: 0;
      height: 100%;
      display: inline-block;
      background-color: rgba(0, 57, 138, 0.2); }
    section.history .history-layout #tabs .tabs-list .tabs-item {
      background: transparent;
      border: none; }
      section.history .history-layout #tabs .tabs-list .tabs-item .tabs-item-layout {
        display: flex;
        flex-wrap: wrap;
        justify-content: space-around; }

section.history .history-layout .item-action.action-past {
  text-align: center; }
  section.history .history-layout .item-action.action-past .button {
    background-color: #3BB7FF;
    color: #FFFFFF; }
    section.history .history-layout .item-action.action-past .button:hover, section.history .history-layout .item-action.action-past .button:active, section.history .history-layout .item-action.action-past .button:focus {
      background-color: #D4AA5C; }

.funeral-services {
  position: relative;
  display: flex;
  background-color: #FFFFFF;
  margin: 4px;
  margin-bottom: 8px;
  padding: 1rem;
  outline: 2px solid rgba(0, 57, 138, 0.3);
  outline-offset: -2px;
  max-width: 270px;
  max-height: 200px;
  min-width: 270px;
  min-height: 170px; }
  .funeral-services .action-map {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 90px;
    height: 57px;
    z-index: 1; }
  .funeral-services .services-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between; }
    .funeral-services .services-layout .details-name {
      color: #00398A;
      font-size: 20px;
      position: relative;
      width: max-content;
      font-family: "Overpass", sans-serif;
      width: 100%; }
      .funeral-services .services-layout .details-name:after {
        position: absolute;
        bottom: -2px;
        left: 0;
        content: "";
        display: inline-block;
        height: 2px;
        width: 60px;
        background-color: #D4AA5C; }
    .funeral-services .services-layout .details-wrap {
      display: flex;
      font-family: "Overpass", sans-serif;
      padding: 8px 0; }
      .funeral-services .services-layout .details-wrap .details-img {
        height: 64px;
        width: 64px;
        margin-right: 8px;
        overflow: hidden; }
        .funeral-services .services-layout .details-wrap .details-img figure {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 100%;
          width: 100%; }
      .funeral-services .services-layout .details-wrap .details-info {
        display: flex;
        flex-direction: column; }
        .funeral-services .services-layout .details-wrap .details-info .info-funeral,
        .funeral-services .services-layout .details-wrap .details-info .info-burial {
          color: #00398A;
          font-size: 1rem;
          font-weight: bold; }
    .funeral-services .services-layout .view-more a {
      position: relative;
      color: #5A5A5A;
      font-size: 18px;
      font-family: "Overpass", sans-serif; }
      .funeral-services .services-layout .view-more a:after {
        position: absolute;
        content: "\e901";
        font-family: "icomoon";
        right: -32px;
        top: 50%;
        transform: translateY(-50%);
        font-size: 10px;
        color: #D4AA5C; }
      .funeral-services .services-layout .view-more a:hover, .funeral-services .services-layout .view-more a:active, .funeral-services .services-layout .view-more a:focus {
        color: #D4AA5C;
        text-decoration: underline; }

@media screen and (max-width: 768px) {
  section.history .history-layout #tabs .tabs-header > li a {
    padding: 0 6px;
    font-size: 18px; }
  section.history .history-layout #tabs .tabs-list .tabs-item .tabs-item-layout .funeral-services:nth-child(n + 5) {
    display: none; } }

section.services-home {
  margin-bottom: 4rem; }
  section.services-home .container .services-home-layout {
    display: flex;
    align-items: stretch;
    justify-content: space-between; }
    section.services-home .container .services-home-layout .services-item {
      display: flex;
      max-width: 48%;
      flex-grow: 1;
      min-height: 320px;
      background-repeat: no-repeat;
      background-size: cover;
      background-position: center center; }
      section.services-home .container .services-home-layout .services-item .item-layout {
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: center;
        width: 100%;
        background-color: rgba(27, 84, 189, 0.4);
        padding: 1rem;
        outline: 1px solid #FFFFFF;
        outline-offset: -1rem;
        transition: 0.2s; }
        section.services-home .container .services-home-layout .services-item .item-layout:hover {
          background-color: rgba(0, 0, 0, 0);
          transition: 0.2s; }
        section.services-home .container .services-home-layout .services-item .item-layout .item-title {
          font-family: "Montserrat", sans-serif;
          font-size: 36px;
          color: #FFFFFF;
          font-weight: 600;
          text-align: center; }
        section.services-home .container .services-home-layout .services-item .item-layout .item-action {
          color: #FFFFFF;
          font-size: 1.2rem;
          text-decoration: underline;
          text-transform: uppercase; }

@media screen and (max-width: 1000px) {
  section.services-home .container {
    padding: 0 1rem; }
    section.services-home .container .services-home-layout {
      flex-direction: column; }
      section.services-home .container .services-home-layout .services-item {
        max-width: 100%;
        flex-grow: 1;
        width: 100%;
        margin-bottom: 1rem; } }

section.shabbat {
  background-color: #00398A;
  margin-bottom: 4rem; }
  section.shabbat .shabbat-layout {
    padding: 1.6rem 4rem; }
    section.shabbat .shabbat-layout *:not(a) {
      color: #FFFFFF; }
    section.shabbat .shabbat-layout .hebcal-container > div {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;
      align-items: center; }
      section.shabbat .shabbat-layout .hebcal-container > div > h3,
      section.shabbat .shabbat-layout .hebcal-container > div > ul {
        min-width: 300px;
        max-width: 500px;
        margin: 8px 0; }
      section.shabbat .shabbat-layout .hebcal-container > div > h3 {
        font-family: "Montserrat", sans-serif;
        font-weight: bold;
        color: #D4AA5C;
        font-size: 24px;
        padding-left: 60px;
        position: relative; }
        section.shabbat .shabbat-layout .hebcal-container > div > h3:before {
          content: "\e903";
          font-family: 'icomoon';
          font-size: 4rem;
          position: absolute;
          left: 0;
          bottom: 0;
          color: #FFFFFF; }
      section.shabbat .shabbat-layout .hebcal-container > div .hebcal-results > li {
        position: relative;
        padding-left: 12px;
        font-size: 18px; }
        section.shabbat .shabbat-layout .hebcal-container > div .hebcal-results > li:before {
          content: "\e902";
          font-family: 'icomoon';
          position: absolute;
          left: 0;
          top: 6px;
          color: #D4AA5C;
          font-size: 10px; }
        section.shabbat .shabbat-layout .hebcal-container > div .hebcal-results > li a {
          text-decoration: underline; }
      section.shabbat .shabbat-layout .hebcal-container > div .copyright {
        display: none; }

section.welcome {
  margin-bottom: 4rem; }
  section.welcome .welcome-layout {
    display: flex;
    align-items: center;
    padding: 0 0 3rem 0; }
    section.welcome .welcome-layout .item-title {
      min-width: 270px;
      color: #00398A;
      font-size: 2.2rem;
      font-family: "Montserrat", sans-serif; }
      section.welcome .welcome-layout .item-title h1 {
        font-weight: bold;
        font-size: inherit;
        font-family: inherit; }
    section.welcome .welcome-layout .item-text {
      font-size: 14px; }
  section.welcome .announcements {
    border: 1px solid #00398A;
    padding: 2rem; }
    section.welcome .announcements .announcement-text {
      color: #00398A;
      text-align: center;
      font-size: 18px; }
      section.welcome .announcements .announcement-text a {
        position: relative; }
        section.welcome .announcements .announcement-text a::before {
          position: absolute;
          content: "\e901";
          font-family: 'icomoon';
          right: -26px;
          top: 7px;
          font-size: 8px; }
        section.welcome .announcements .announcement-text a:hover, section.welcome .announcements .announcement-text a:active, section.welcome .announcements .announcement-text a:focus {
          text-decoration: underline; }

@media screen and (max-width: 768px) {
  section.welcome .welcome-layout {
    flex-direction: column; }
    section.welcome .welcome-layout .item-title {
      font-size: 1.8rem;
      text-align: center; } }

section.donation {
  background-color: #3BB7FF;
  padding: 3rem 0; }
  section.donation .donation-layout {
    display: flex;
    align-items: center;
    justify-content: space-between; }
    section.donation .donation-layout p {
      font-family: "Montserrat", sans-serif;
      color: #FFFFFF;
      max-width: 900px;
      margin: 0.8rem 0;
      font-weight: 600;
      font-size: 26px; }
    section.donation .donation-layout a {
      min-width: 200px; }

@media screen and (max-width: 768px) {
  section.donation .donation-layout {
    flex-direction: column;
    text-align: center; } }

section.get-in-touch {
  margin-bottom: 4rem; }
  section.get-in-touch .get-in-touch-layout .form-layout {
    display: flex;
    flex-wrap: wrap;
    justify-content: center; }
    section.get-in-touch .get-in-touch-layout .form-layout .layout-item.item-wrap {
      max-width: 370px;
      padding: 0 1rem; }
    section.get-in-touch .get-in-touch-layout .form-layout .layout-item.item-submit {
      width: 100%;
      text-align: center; }
      section.get-in-touch .get-in-touch-layout .form-layout .layout-item.item-submit input[type=submit]:hover, section.get-in-touch .get-in-touch-layout .form-layout .layout-item.item-submit input[type=submit]:active, section.get-in-touch .get-in-touch-layout .form-layout .layout-item.item-submit input[type=submit]:focus {
        background-color: #3BB7FF; }

section.video {
  background-color: #EFF0F2; }
  section.video .video-layout {
    column-count: 2;
    padding: 3rem 0;
    margin-bottom: 4rem; }
    section.video .video-layout .layout-item {
      padding: 2rem; }
      section.video .video-layout .layout-item .item-title p {
        font-size: 24px; }
        section.video .video-layout .layout-item .item-title p:before, section.video .video-layout .layout-item .item-title p:after {
          background-color: rgba(70, 111, 166, 0.3); }
      section.video .video-layout .layout-item .item-video {
        position: relative;
        padding-bottom: 56.25%;
        padding-top: 30px;
        height: 0;
        overflow: hidden; }
        section.video .video-layout .layout-item .item-video iframe {
          position: absolute;
          top: 0;
          left: 0;
          width: 100%;
          height: 100%; }

@media screen and (max-width: 768px) {
  section.video .video-layout {
    column-count: 1; } }

section.content {
  margin-bottom: 4rem; }
  section.content h2, section.content strong {
    text-transform: uppercase;
    font-size: 18px;
    font-weight: bold; }
  section.content h3 {
    font-family: "Montserrat", sans-serif;
    font-size: 20px;
    color: #153059; }
  section.content p {
    line-height: 1.8;
    margin-bottom: 14px; }
  section.content ul li, section.content ol li {
    line-height: 1.8; }
  section.content ul {
    list-style: disc; }
  section.content .sc_player_container1 {
    display: inline-block; }
    section.content .sc_player_container1 .myButton_play {
      background: url(/wp-content/themes/steeles-new/img/play.png) !important; }
    section.content .sc_player_container1 .myButton_stop {
      background: url(/wp-content/themes/steeles-new/img/pause.png) !important; }
    section.content .sc_player_container1 .myButton_play,
    section.content .sc_player_container1 .myButton_stop {
      background-size: contain !important;
      min-width: auto;
      min-height: auto;
      vertical-align: middle; }
  section.content .thumbnail-img {
    float: right;
    margin-left: 1rem; }
    section.content .thumbnail-img img {
      max-width: 400px; }

@media screen and (max-width: 768px) {
  section.content .thumbnail-img {
    float: none;
    margin: 0;
    text-align: center; }
    section.content .thumbnail-img img {
      max-width: 260px; } }

section.advantages .advantages-layout .item-details {
  display: flex;
  justify-content: space-around;
  margin-bottom: 3rem; }
  section.advantages .advantages-layout .item-details .details-wrap .details-logo {
    font-size: 4rem;
    text-align: center; }
  section.advantages .advantages-layout .item-details .details-wrap .details-text {
    color: #00398A;
    font-size: 20px;
    text-transform: uppercase;
    text-align: center;
    font-weight: bold; }

@media screen and (max-width: 768px) {
  section.advantages .advantages-layout .item-details {
    flex-direction: column; } }

section.testimonials .testimonials-wrap {
  box-shadow: 0 0 16px rgba(110, 110, 110, 0.4);
  margin: 0 0 2rem; }
  section.testimonials .testimonials-wrap .testimonials-layout {
    display: flex;
    align-items: center;
    padding: 1rem; }
    section.testimonials .testimonials-wrap .testimonials-layout .item-img {
      background-color: #00398A;
      border-radius: 50%;
      display: flex;
      height: 70px;
      width: 70px;
      min-width: 70px;
      align-items: center;
      justify-content: center;
      margin-right: 1rem; }
      section.testimonials .testimonials-wrap .testimonials-layout .item-img img {
        max-width: 40px; }
    section.testimonials .testimonials-wrap .testimonials-layout .item-info {
      flex-grow: 1; }
      section.testimonials .testimonials-wrap .testimonials-layout .item-info .info-title {
        font-size: 18px;
        font-weight: bold; }
      section.testimonials .testimonials-wrap .testimonials-layout .item-info .info-text p {
        margin: 0; }

section.records {
  padding: 4rem 0; }
  section.records .records-tabs #records-tabs-caption {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    list-style: none;
    position: relative;
    padding: 0;
    margin: 8px auto;
    background-color: #3BB7FF; }
    section.records .records-tabs #records-tabs-caption label {
      padding: 8px 14px;
      margin: 2px;
      font-size: 24px;
      color: #00398A;
      text-decoration: underline;
      position: relative;
      text-align: center;
      cursor: default;
      font-family: "Montserrat", sans-serif;
      font-weight: 600; }
      section.records .records-tabs #records-tabs-caption label:not(.active) {
        cursor: pointer;
        color: #FFFFFF; }
        section.records .records-tabs #records-tabs-caption label:not(.active):hover {
          color: #00398A; }
      section.records .records-tabs #records-tabs-caption label input {
        opacity: 0; }
    section.records .records-tabs #records-tabs-caption .active {
      user-select: none; }
  section.records .records-tabs .records-tabs-content.active {
    display: flex; }
  section.records .records-tabs .records-tabs-content .records-tabs-content-layout {
    display: flex;
    flex-wrap: wrap; }
    section.records .records-tabs .records-tabs-content .records-tabs-content-layout .funeral-row {
      display: flex;
      flex-wrap: wrap;
      padding: 1rem 0; }
      section.records .records-tabs .records-tabs-content .records-tabs-content-layout .funeral-row p {
        margin: 0; }
      section.records .records-tabs .records-tabs-content .records-tabs-content-layout .funeral-row .funeral-row-date {
        width: 100%;
        text-transform: uppercase;
        color: #D4AA5C;
        padding: 0 10px;
        font-weight: bold; }
      section.records .records-tabs .records-tabs-content .records-tabs-content-layout .funeral-row .funeral-services {
        margin: 10px; }
    section.records .records-tabs .records-tabs-content .records-tabs-content-layout hr {
      margin: 10px; }

@media screen and (max-width: 768px) {
  section.records {
    padding: 2rem 0; }
    section.records .records-tabs #records-tabs-caption {
      flex: 1 0 auto; }
      section.records .records-tabs #records-tabs-caption label {
        font-size: 18px; } }

section.contact-map {
  margin-bottom: 3rem; }
  section.contact-map .contact-map-layout #mapContact {
    width: 100%;
    height: 400px;
    background-color: grey; }

section.contact-actions {
  background-color: #00398A;
  margin-bottom: 3rem; }
  section.contact-actions .contact-actions-layout {
    display: flex;
    justify-content: space-around;
    flex-wrap: wrap;
    align-items: flex-start;
    padding: 2rem 1rem; }
    section.contact-actions .contact-actions-layout .layout-item {
      color: #FFFFFF;
      text-align: center;
      min-width: 200px;
      max-width: 220px;
      margin: 1rem; }
      section.contact-actions .contact-actions-layout .layout-item .title {
        font-size: 18px;
        color: #3BB7FF; }
      section.contact-actions .contact-actions-layout .layout-item a {
        color: #FFFFFF; }
        section.contact-actions .contact-actions-layout .layout-item a:hover, section.contact-actions .contact-actions-layout .layout-item a:active, section.contact-actions .contact-actions-layout .layout-item a:focus {
          color: #D4AA5C; }
      section.contact-actions .contact-actions-layout .layout-item span {
        font-size: 3rem; }

section.contact-form .contact-form-layout {
  max-width: 770px;
  margin: 0 auto; }
  section.contact-form .contact-form-layout .layout-item {
    margin: 1rem 0; }
    section.contact-form .contact-form-layout .layout-item input.warn_border,
    section.contact-form .contact-form-layout .layout-item textarea.warn_border {
      border: 1px solid #EB5757; }
    section.contact-form .contact-form-layout .layout-item span.asterix {
      color: #EB5757; }
  section.contact-form .contact-form-layout .item-slice {
    display: flex;
    justify-content: space-between;
    flex-wrap: wrap; }
    section.contact-form .contact-form-layout .item-slice > div {
      flex-grow: 1;
      max-width: 48%; }
  section.contact-form .contact-form-layout .item-action {
    display: flex;
    justify-content: center;
    flex-wrap: wrap; }
    section.contact-form .contact-form-layout .item-action input.main {
      margin: 1rem;
      background-color: #D4AA5C; }
      section.contact-form .contact-form-layout .item-action input.main:hover, section.contact-form .contact-form-layout .item-action input.main:active, section.contact-form .contact-form-layout .item-action input.main:focus {
        background-color: #3BB7FF; }
    section.contact-form .contact-form-layout .item-action input.second {
      margin: 1rem;
      background-color: #3BB7FF; }
      section.contact-form .contact-form-layout .item-action input.second:hover, section.contact-form .contact-form-layout .item-action input.second:active, section.contact-form .contact-form-layout .item-action input.second:focus {
        background-color: #D4AA5C; }
  section.contact-form .contact-form-layout .message {
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 1rem;
    background-color: #b4e6c8;
    margin-bottom: 2rem; }
    section.contact-form .contact-form-layout .message .close {
      display: none; }

.page-template-page-calculator section.content .content-layout {
  display: flex;
  align-items: flex-start; }
  .page-template-page-calculator section.content .content-layout .item-text {
    padding: 1rem; }
    .page-template-page-calculator section.content .content-layout .item-text .schedule-wrapper .schedule-wrapper-layout {
      display: flex;
      align-items: flex-end;
      background-color: white; }
      .page-template-page-calculator section.content .content-layout .item-text .schedule-wrapper .schedule-wrapper-layout .layout-item {
        padding-right: 1rem; }
        .page-template-page-calculator section.content .content-layout .item-text .schedule-wrapper .schedule-wrapper-layout .layout-item span {
          font-weight: bold; }
        .page-template-page-calculator section.content .content-layout .item-text .schedule-wrapper .schedule-wrapper-layout .layout-item .item-title {
          font-family: "Montserrat", sans-serif;
          font-size: 24px; }
        .page-template-page-calculator section.content .content-layout .item-text .schedule-wrapper .schedule-wrapper-layout .layout-item .item-name {
          text-transform: uppercase;
          font-weight: 600; }
  .page-template-page-calculator section.content .content-layout .item-calculator {
    max-width: 370px;
    min-width: 300px;
    background-color: #EFF0F2;
    padding: 16px; }
    .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout .layout-item:not(:last-child) {
      margin-bottom: 1rem; }
    .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout label {
      margin: 10px 0; }
    .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout input[type=checkbox] {
      margin-right: 6px; }
    .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout .item-filter {
      display: flex;
      justify-content: space-between;
      align-items: center; }
      .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout .item-filter select {
        padding-right: 20px;
        max-width: 120px; }
    .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout .item-date {
      display: flex;
      justify-content: space-between; }
      .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout .item-date .month {
        max-width: 120px; }
        .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout .item-date .month select {
          padding-right: 20px; }
      .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout .item-date .day,
      .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout .item-date .year {
        max-width: 60px; }
    .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout .item-years label {
      width: 100%; }
    .page-template-page-calculator section.content .content-layout .item-calculator #calculator .form-layout .item-checkbox {
      display: flex;
      flex-direction: column; }

@media screen and (max-width: 1000px) {
  .page-template-page-calculator section.content .content-layout {
    flex-direction: column;
    align-items: center; } }

@media screen and (max-width: 768px) {
  .page-template-page-calculator section.content .content-layout .item-text .schedule-wrapper .schedule-wrapper-layout {
    flex-direction: column;
    align-items: flex-start; } }

.page-template-template-cemeteries .cemetries-map .cemetries-map-layout #mapSemetries {
  height: 460px; }

.page-template-template-cemeteries .cemetries-list .cemetries-list-layout {
  padding: 3rem 0; }
  .page-template-template-cemeteries .cemetries-list .cemetries-list-layout .list-layout-item {
    border: 1px solid #00398A;
    padding: 30px 90px;
    margin-bottom: 24px; }
    .page-template-template-cemeteries .cemetries-list .cemetries-list-layout .list-layout-item .item-title {
      display: inline-block;
      font-size: 16px;
      color: #00398A;
      text-transform: uppercase;
      margin-bottom: 8px; }
    .page-template-template-cemeteries .cemetries-list .cemetries-list-layout .list-layout-item .item-description {
      font-size: 14px;
      font-family: "Overpass", sans-serif;
      color: #5A5A5A;
      margin-top: 0; }
    .page-template-template-cemeteries .cemetries-list .cemetries-list-layout .list-layout-item .item-phone {
      color: #5A5A5A;
      position: relative;
      padding-left: 20px; }
      .page-template-template-cemeteries .cemetries-list .cemetries-list-layout .list-layout-item .item-phone:before {
        content: "\e909";
        color: #d6a33a;
        font-family: 'icomoon' !important;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px; }

@media screen and (max-width: 768px) {
  .page-template-template-cemeteries .cemetries-map .cemetries-map-layout #mapSemetries {
    height: 360px; }
  .page-template-template-cemeteries .cemetries-list .cemetries-list-layout .list-layout-item {
    padding: 20px 40px; } }

.page-template-template-synagogues .synagogues-list .synagogues-list-layout {
  padding: 3rem 0; }
  .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item {
    border: 1px solid #00398A;
    padding: 30px 90px;
    margin-bottom: 24px;
    display: flex;
    flex-direction: column; }
    .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item .item-title {
      display: inline-block;
      font-size: 16px;
      color: #00398A;
      text-transform: uppercase;
      margin-bottom: 8px; }
    .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item .item-description {
      font-size: 14px;
      font-family: "Overpass", sans-serif;
      color: #5A5A5A;
      margin-top: 0;
      position: relative;
      padding-left: 24px; }
      .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item .item-description:before {
        content: "\e914";
        font-family: 'icomoon' !important;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px;
        border: 1px solid #D4AA5C;
        color: #D4AA5C;
        border-radius: 50%;
        width: 20px;
        height: 20px;
        text-align: center; }
    .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item .item-phone {
      color: #5A5A5A;
      position: relative;
      padding-left: 24px; }
      .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item .item-phone:before {
        content: "\e909";
        color: #d6a33a;
        font-family: 'icomoon' !important;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px; }
      .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item .item-phone:hover, .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item .item-phone:active, .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item .item-phone:focus {
        color: #D4AA5C; }
    .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item .item-address {
      color: #5A5A5A;
      position: relative;
      padding-left: 24px; }
      .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item .item-address:before {
        content: "\e907";
        color: #d6a33a;
        font-family: 'icomoon' !important;
        position: absolute;
        top: 0;
        left: 0;
        font-size: 14px; }

@media screen and (max-width: 768px) {
  .page-template-template-synagogues .synagogues-list .synagogues-list-layout .list-layout-item {
    padding: 20px 40px; } }

.single-condolence .single-condolence-profile {
  margin-bottom: 4rem; }
  .single-condolence .single-condolence-profile .profile-description {
    display: flex;
    background-color: #EFF0F2;
    padding: 40px;
    margin-bottom: 2rem; }
    .single-condolence .single-condolence-profile .profile-description .description-photo {
      width: 230px;
      height: 230px;
      flex-shrink: 0; }
      .single-condolence .single-condolence-profile .profile-description .description-photo figure {
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;
        height: 100%;
        width: 100%; }
    .single-condolence .single-condolence-profile .profile-description .post-content {
      flex-shrink: 1;
      padding: 0 30px;
      font-size: 18px;
      line-height: 28px; }
  .single-condolence .single-condolence-profile .profile-details .profile-details-layout .item-row {
    margin: 8px 0;
    display: flex;
    font-size: 18px; }
    .single-condolence .single-condolence-profile .profile-details .profile-details-layout .item-row .title {
      display: inline-block;
      min-width: 300px;
      font-weight: bold; }
  .single-condolence .single-condolence-profile .profile-map .profile-map-layout {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    flex-wrap: wrap;
    padding: 1rem 0; }
    .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item {
      display: flex;
      flex-direction: column;
      margin: 1.5rem;
      max-width: 260px;
      font-size: 18px; }
      .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item:first-child {
        margin-left: 0; }
      .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item:last-child {
        margin-right: 0; }
      .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item p {
        margin: 0; }
      .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item .item-title {
        margin-bottom: 8px; }
      .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item .item-map {
        height: 200px;
        width: 270px;
        margin-bottom: 8px; }
      .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item .item-more {
        text-decoration: underline;
        color: #00398A;
        text-transform: capitalize;
        font-weight: 600; }
        .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item .item-more:hover, .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item .item-more:active, .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item .item-more:focus {
          color: #D4AA5C; }

.single-condolence .item-form {
  margin-bottom: 2rem; }
  .single-condolence .item-form #commentform {
    display: flex;
    flex-wrap: wrap;
    align-items: center;
    justify-content: space-between;
    max-width: 770px;
    margin: 0 auto; }
    .single-condolence .item-form #commentform .comment-notes {
      text-align: center;
      width: 100%; }
    .single-condolence .item-form #commentform .comment-form-comment {
      width: 100%; }
      .single-condolence .item-form #commentform .comment-form-comment textarea {
        min-height: 110px;
        max-height: 120px; }
    .single-condolence .item-form #commentform .comment-form-author {
      width: 49%; }
    .single-condolence .item-form #commentform .comment-form-email {
      width: 49%; }
    .single-condolence .item-form #commentform .comment-form-cookies-consent {
      display: flex;
      align-items: center;
      justify-content: center;
      width: 100%; }
      .single-condolence .item-form #commentform .comment-form-cookies-consent input[type=checkbox] {
        margin-right: 12px; }
    .single-condolence .item-form #commentform .form-submit {
      width: 100%;
      text-align: center; }
    .single-condolence .item-form #commentform span.required {
      color: #EB5757; }

.single-condolence .comments {
  padding: 3rem 0; }
  .single-condolence .comments .comments-layout .comment-single {
    border: 1px solid #00398A;
    padding: 30px 90px;
    margin-bottom: 24px; }
    .single-condolence .comments .comments-layout .comment-single .comment-content-layout .comment-time {
      display: block;
      font-size: 12px;
      font-family: "Overpass", sans-serif;
      color: #9e9d9d;
      margin-bottom: 8px; }
    .single-condolence .comments .comments-layout .comment-single .comment-content-layout .title {
      display: block;
      font-size: 16px;
      color: #00398A;
      text-transform: uppercase;
      margin-bottom: 8px; }
    .single-condolence .comments .comments-layout .comment-single .comment-content-layout .description {
      font-size: 14px;
      font-family: "Overpass", sans-serif;
      color: #5A5A5A; }
    .single-condolence .comments .comments-layout .comment-single .comment-content-layout .links {
      display: none; }

@media screen and (max-width: 1024px) {
  .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item:last-child {
    margin-left: 0; } }

@media screen and (max-width: 768px) {
  .single-condolence .single-condolence-profile .profile-description {
    flex-direction: column;
    align-items: center; }
    .single-condolence .single-condolence-profile .profile-description .post-content {
      font-size: 16px;
      line-height: 24px; }
    .single-condolence .single-condolence-profile .profile-description .description-photo {
      margin-bottom: 1rem; }
  .single-condolence .single-condolence-profile .profile-details .profile-details-layout .item-row {
    flex-direction: column;
    margin-bottom: 1rem; }
    .single-condolence .single-condolence-profile .profile-details .profile-details-layout .item-row .title {
      min-width: none; }
  .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item:nth-child(3) {
    margin-left: 0; }
  .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item:last-child {
    margin-left: 1.5rem; }
  .single-condolence .comments .comments-layout .comment-single {
    padding: 20px 40px; } }

@media screen and (max-width: 576) {
  .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item:nth-child(2), .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item:nth-child(3), .single-condolence .single-condolence-profile .profile-map .profile-map-layout .layout-item:last-child {
    margin-left: 0; } }

.single-cemetery section.single-cemetry .single-cemetry-layout .item-map {
  position: relative; }
  .single-cemetery section.single-cemetry .single-cemetry-layout .item-map .map-info {
    position: absolute;
    top: 0;
    right: 0;
    display: inline-block;
    height: 100%;
    width: 300px;
    background-color: #00398A;
    padding: 2rem; }
    .single-cemetery section.single-cemetry .single-cemetry-layout .item-map .map-info .info-item {
      position: relative;
      margin-bottom: 16px;
      padding-left: 46px;
      color: #FFFFFF; }
      .single-cemetery section.single-cemetry .single-cemetry-layout .item-map .map-info .info-item .item-title {
        font-size: 18px;
        color: #3BB7FF; }
      .single-cemetery section.single-cemetry .single-cemetry-layout .item-map .map-info .info-item a {
        color: #FFFFFF; }
        .single-cemetery section.single-cemetry .single-cemetry-layout .item-map .map-info .info-item a:hover, .single-cemetery section.single-cemetry .single-cemetry-layout .item-map .map-info .info-item a:active, .single-cemetery section.single-cemetry .single-cemetry-layout .item-map .map-info .info-item a:focus {
          color: #D4AA5C; }
      .single-cemetery section.single-cemetry .single-cemetry-layout .item-map .map-info .info-item span {
        position: absolute;
        left: 0;
        top: 8px;
        font-size: 30px; }

.single-cemetery section.single-cemetry .single-cemetry-layout .item-gallery .ngg-gallery-thumbnail {
  border: none; }

.single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout {
  display: flex; }
  .single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout .item-text {
    margin-right: 1rem; }
    .single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout .item-text .su-spoiler-style-default > .su-spoiler-title {
      padding: 0;
      font-size: 18px; }
      .single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout .item-text .su-spoiler-style-default > .su-spoiler-title .su-spoiler-icon {
        display: none; }
      .single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout .item-text .su-spoiler-style-default > .su-spoiler-title:hover, .single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout .item-text .su-spoiler-style-default > .su-spoiler-title:active, .single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout .item-text .su-spoiler-style-default > .su-spoiler-title:focus {
        cursor: pointer;
        color: #D4AA5C;
        outline: none; }
  .single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout .item-document {
    max-width: 360px;
    flex-shrink: 0; }
    .single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout .item-document a {
      color: transparent; }

@media screen and (max-width: 768px) {
  .single-cemetery section.single-cemetry .single-cemetry-layout .item-map .map-info {
    position: static;
    height: auto;
    width: 100%; }
  .single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout {
    flex-direction: column;
    align-items: center; }
    .single-cemetery section.single-cemetry .single-cemetry-layout .item-info .content-layout .item-text {
      margin-right: 0; } }

.single-synagogue section.single-synagogue .single-synagogue-layout .item-map .map-info {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  background-color: #00398A;
  padding: 2rem 4rem; }
  .single-synagogue section.single-synagogue .single-synagogue-layout .item-map .map-info .info-item {
    position: relative;
    margin-bottom: 16px;
    padding-left: 46px;
    width: 400px;
    color: #FFFFFF; }
    .single-synagogue section.single-synagogue .single-synagogue-layout .item-map .map-info .info-item .item-title {
      font-size: 18px;
      color: #3BB7FF; }
    .single-synagogue section.single-synagogue .single-synagogue-layout .item-map .map-info .info-item a {
      color: #FFFFFF; }
      .single-synagogue section.single-synagogue .single-synagogue-layout .item-map .map-info .info-item a:hover, .single-synagogue section.single-synagogue .single-synagogue-layout .item-map .map-info .info-item a:active, .single-synagogue section.single-synagogue .single-synagogue-layout .item-map .map-info .info-item a:focus {
        color: #D4AA5C; }
    .single-synagogue section.single-synagogue .single-synagogue-layout .item-map .map-info .info-item span {
      position: absolute;
      left: 0;
      top: 8px;
      font-size: 30px; }

@media screen and (max-width: 768px) {
  .single-cemetery section.single-cemetry .single-cemetry-layout .item-map .map-info {
    padding: 2rem; } }

section.calendar {
  margin-bottom: 4rem; }
  section.calendar .container .wpfc-calendar-wrapper {
    font-family: "Overpass", sans-serif; }
    section.calendar .container .wpfc-calendar-wrapper button {
      min-width: auto;
      min-height: auto; }
      section.calendar .container .wpfc-calendar-wrapper button.fc-state-default {
        border-radius: 2px;
        background-color: transparent;
        background-image: none;
        font-family: "Overpass", sans-serif;
        font-size: 14px !important;
        border-color: #ccc; }
      section.calendar .container .wpfc-calendar-wrapper button.fc-state-active, section.calendar .container .wpfc-calendar-wrapper button.fc-state-down {
        border-color: rgba(0, 57, 138, 0.3);
        background-color: transparent;
        background-image: none;
        box-shadow: none; }
      section.calendar .container .wpfc-calendar-wrapper button.fc-today-button {
        color: #3BB7FF; }
      section.calendar .container .wpfc-calendar-wrapper button.fc-prev-button span.fc-icon, section.calendar .container .wpfc-calendar-wrapper button.fc-next-button span.fc-icon {
        border-radius: 50%;
        background-color: #D4AA5C;
        overflow: visible;
        height: 18px;
        width: 18px; }
        section.calendar .container .wpfc-calendar-wrapper button.fc-prev-button span.fc-icon:after, section.calendar .container .wpfc-calendar-wrapper button.fc-next-button span.fc-icon:after {
          position: absolute;
          top: 2px;
          left: 1px;
          display: inline-block;
          font-family: icomoon !important;
          font-weight: 400;
          font-size: 17px;
          margin: 0;
          color: #fff; }
        section.calendar .container .wpfc-calendar-wrapper button.fc-prev-button span.fc-icon.fc-icon-left-single-arrow:after, section.calendar .container .wpfc-calendar-wrapper button.fc-next-button span.fc-icon.fc-icon-left-single-arrow:after {
          content: "\e91a" !important; }
        section.calendar .container .wpfc-calendar-wrapper button.fc-prev-button span.fc-icon.fc-icon-right-single-arrow:after, section.calendar .container .wpfc-calendar-wrapper button.fc-next-button span.fc-icon.fc-icon-right-single-arrow:after {
          content: "\e918" !important; }
    section.calendar .container .wpfc-calendar-wrapper h2 {
      font-size: 18px !important; }
    section.calendar .container .wpfc-calendar-wrapper .fc-head-container {
      background: #D4AA5C; }
      section.calendar .container .wpfc-calendar-wrapper .fc-head-container .fc-day-header {
        text-transform: uppercase;
        font-size: 15px !important;
        font-family: "Overpass", sans-serif;
        color: #FFFFFF;
        padding: 8px; }
    section.calendar .container .wpfc-calendar-wrapper .fc-event-time,
    section.calendar .container .wpfc-calendar-wrapper .fc-time {
      display: none; }
    section.calendar .container .wpfc-calendar-wrapper .fc-day {
      background: linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 100%);
      border: 1px solid rgba(0, 57, 138, 0.3); }
    section.calendar .container .wpfc-calendar-wrapper a.fc-event {
      background-color: #00398A !important; }
      section.calendar .container .wpfc-calendar-wrapper a.fc-event * {
        font-size: 10px !important; }
    section.calendar .container .wpfc-calendar-wrapper .fc-unthemed .fc-today {
      background: transparent; }

@media screen and (max-width: 567px) {
  section.calendar .container .wpfc-calendar-wrapper .fc-toolbar {
    display: flex;
    flex-direction: column;
    align-items: center; }
    section.calendar .container .wpfc-calendar-wrapper .fc-toolbar .fc-center {
      order: 0;
      margin-bottom: 1rem; }
    section.calendar .container .wpfc-calendar-wrapper .fc-toolbar .fc-left {
      order: 1;
      margin-bottom: 1rem; }
    section.calendar .container .wpfc-calendar-wrapper .fc-toolbar .fc-right {
      order: 2;
      margin-bottom: 1rem; } }

.single-smcnewsletters .content {
  text-align: center; }
  .single-smcnewsletters .content h1 {
    margin-top: 80px;
    font-size: 30px; }
  .single-smcnewsletters .content h2 {
    margin-top: 30px; }
  .single-smcnewsletters .content a {
    color: #183678;
    text-decoration: underline; }

.search-results section.search-result .search-action,
.search-no-results section.search-result .search-action {
  display: flex;
  align-items: center;
  flex-wrap: wrap;
  max-width: 750px;
  margin: 0 auto; }
  .search-results section.search-result .search-action #searchform,
  .search-no-results section.search-result .search-action #searchform {
    display: flex;
    align-items: center;
    width: 100%; }
    .search-results section.search-result .search-action #searchform input[type=text],
    .search-no-results section.search-result .search-action #searchform input[type=text] {
      margin-right: 1rem; }
    .search-results section.search-result .search-action #searchform input[type=submit],
    .search-no-results section.search-result .search-action #searchform input[type=submit] {
      background-color: #3BB7FF;
      color: #FFFFFF;
      padding: 8px 14px;
      min-width: auto; }
    .search-results section.search-result .search-action #searchform .result-count,
    .search-no-results section.search-result .search-action #searchform .result-count {
      width: 100%; }

.search-results section.search-result .search-result-layout,
.search-no-results section.search-result .search-result-layout {
  display: flex;
  flex-wrap: wrap;
  padding: 1rem 0; }
  .search-results section.search-result .search-result-layout .layout-item,
  .search-no-results section.search-result .search-result-layout .layout-item {
    border: 1px solid #00398A;
    padding: 1rem;
    margin: 1rem 10px;
    width: 270px;
    min-height: 160px; }
    .search-results section.search-result .search-result-layout .layout-item .item-title,
    .search-no-results section.search-result .search-result-layout .layout-item .item-title {
      display: inline-block;
      font-size: 16px;
      color: #00398A;
      text-transform: uppercase;
      margin-bottom: 8px;
      text-decoration: underline;
      font-weight: bold; }
      .search-results section.search-result .search-result-layout .layout-item .item-title:hover, .search-results section.search-result .search-result-layout .layout-item .item-title:active, .search-results section.search-result .search-result-layout .layout-item .item-title:focus,
      .search-no-results section.search-result .search-result-layout .layout-item .item-title:hover,
      .search-no-results section.search-result .search-result-layout .layout-item .item-title:active,
      .search-no-results section.search-result .search-result-layout .layout-item .item-title:focus {
        color: #D4AA5C; }
    .search-results section.search-result .search-result-layout .layout-item .item-time,
    .search-no-results section.search-result .search-result-layout .layout-item .item-time {
      display: inline-block;
      margin-bottom: 8px; }

.search-results section.search-result .search-error,
.search-no-results section.search-result .search-error {
  font-size: 24px;
  margin: 1rem auto;
  text-align: center; }

section.page-404 .page-404-layout {
  display: flex;
  align-items: center;
  justify-content: space-around;
  padding: 4rem 0;
  flex-wrap: wrap; }
  section.page-404 .page-404-layout .item-logo {
    width: 60%; }
    section.page-404 .page-404-layout .item-logo .logo-status {
      color: #3BB7FF;
      font-weight: bold;
      font-size: 16rem;
      font-family: "Montserrat", sans-serif; }
  section.page-404 .page-404-layout .item-return {
    width: 40%;
    padding: 2rem; }
    section.page-404 .page-404-layout .item-return .return-title {
      font-size: 36px;
      font-weight: bold;
      color: #00398A;
      font-family: "Montserrat", sans-serif;
      margin: 2rem 0; }
    section.page-404 .page-404-layout .item-return .return-text {
      font-size: 24px;
      text-transform: uppercase;
      font-weight: bold;
      margin: 2rem 0; }
    section.page-404 .page-404-layout .item-return .button:hover, section.page-404 .page-404-layout .item-return .button:active, section.page-404 .page-404-layout .item-return .button:focus {
      background-color: #3BB7FF; }

@media screen and (max-width: 768px) {
  section.page-404 .page-404-layout {
    flex-direction: column; }
    section.page-404 .page-404-layout .item-logo {
      width: 100%; }
      section.page-404 .page-404-layout .item-logo .logo-status {
        text-align: center;
        font-size: 10rem; }
    section.page-404 .page-404-layout .item-return {
      width: 100%;
      text-align: center; } }
