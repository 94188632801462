  $button-color: #ffffff;
  $button-background: #D6A33A;
  $button-font-family: $global-font-family;
  $button-font-size: 16px;
  $button-font-weight: normal;
  $button-padding: 14px 36px;
  $button-height: $global-control-height;
  $button-min-width: 200px;

  $button-color-hover: #ffffff;
  $button-background-hover: #D6A33A;

  $button-color-focus: #ffffff;
  $button-background-focus: #D6A33A;

  $button-color-active: #ffffff;
  $button-background-active: #D6A33A;

button,
input[type="button"],
input[type="submit"],
.button {
  margin: 0;
  position: relative;
  -webkit-appearance: none;
  -moz-appearance: none;
  border: none;
  user-select: none;
  border-radius: 0;
  overflow: visible;
  font: inherit;
  text-transform: none;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: $button-padding;
  font-family: $button-font-family;
  font-size: $button-font-size;
  min-height: $button-height;
  text-decoration: none;
  background: $button-background;
  color: $button-color;
  min-width: $button-min-width;
  cursor: pointer;
}

.button:not(:disabled) {
  cursor: pointer;
}

.button::-moz-focus-inner {
  border: 0;
  padding: 0;
}

.button:hover {
  text-decoration: none;
  background: $button-background-hover;
  color: $button-color-hover;
}

.button:focus {
  outline: none;
  background: $button-background-focus;
  color: $button-color-focus;
}

.button:active,
.button.is-active {
  background: $button-background-active;
  color: $button-color-active;
}

.button:disabled {
  pointer-events: none;
  opacity: 0.45;
}

.button-secondary {
  background-color: $global-primary-background;
}