section.advantages {
    .advantages-layout {
        .item-details {
            display: flex;
            justify-content: space-around;
            margin-bottom: 3rem;

            .details-wrap {
                .details-logo {
                    font-size: 4rem;
                    text-align: center;
                }

                .details-text {
                    color: $global-primary-background;
                    font-size: 20px;
                    text-transform: uppercase;
                    text-align: center;
                    font-weight: bold;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    section.advantages {
        .advantages-layout {
            .item-details {
                flex-direction: column;
            }
        }
    }
}