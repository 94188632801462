section.content {
    margin-bottom: 4rem;

    h2, strong {
        text-transform: uppercase;
        font-size: 18px;
        font-weight: bold;
    }

    h3 {
        font-family: $montserrat-font-family;
        font-size: 20px;
        color: #153059;
    }

    p {
        line-height: 1.8;
        margin-bottom: 14px;
    }

    ul, ol {
        li {
            line-height: 1.8;
        }
    }

    ul {
        list-style: disc;
    }

    .sc_player_container1 {
        display: inline-block;

        .myButton_play {
            background: url(/wp-content/themes/steeles-new/img/play.png) !important;
        }

        .myButton_stop {
            background: url(/wp-content/themes/steeles-new/img/pause.png) !important;
        }

        .myButton_play,
        .myButton_stop {
            background-size: contain !important;
            min-width: auto;
            min-height: auto;
            vertical-align: middle;
        }
    }

    .thumbnail-img {
        float: right;
        margin-left: 1rem;

        img {
            max-width: 400px;
        }
    }
}

@media screen and (max-width: 768px) {
    section.content {
        .thumbnail-img {
            float: none;
            margin: 0;
            text-align: center;

            img {
                max-width: 260px;
            }
        }
    }
}