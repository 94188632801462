section.video {
    background-color: $global-muted-background;

    .video-layout {
        column-count: 2;

        padding: 3rem 0;
        margin-bottom: 4rem;

        .layout-item {
            padding: 2rem;

            .item-title {
                p {
                    font-size: 24px;

                    &:before,
                    &:after {
                        background-color: rgba(70, 111, 166, 0.3);
                    }
                }
            }

            .item-video {
                position: relative;
                padding-bottom: 56.25%;
                padding-top: 30px;
                height: 0;
                overflow: hidden;

                iframe {
                    position: absolute;
                    top: 0;
                    left: 0;
                    width: 100%;
                    height: 100%;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    section.video {
        .video-layout {
            column-count: 1;
        }
    }
}