  $form-height:                                   $global-control-height;
  $form-line-height:                              1.4;
  $form-padding:                                  6px 14px;

  $form-background:                               transparent;
  $form-color:                                    $global-color;

  $form-focus-background:                         transparent;
  $form-focus-color:                              $global-color;

  $form-disabled-background:                      transparent;
  $form-disabled-color:                           $global-color;

  $form-placeholder-color:                        $global-color;

  $form-invalid-color:                            $global-danger-background;
  $form-valid-color:                              $global-success-background;

  $form-select-padding-right:                     60px;
  $form-select-option-color:                      $global-color;

  $form-radio-size:                               16px;
  $form-radio-margin-top:                         -4px;
  $form-radio-background:                         transparent;

  $form-radio-checked-background:                 transparent;

  $form-radio-checked-focus-background:           transparent;

  $form-radio-disabled-background:                transparent;

  $form-legend-font-size:                         $global-font-size;
  $form-legend-line-height:                       1.4;

  $form-control-margin-bottom:                    $global-margin;

  $form-icon-width:                               $form-height;
  $form-icon-font-size:                           $global-font-size;
  $form-icon-color:                               $global-muted-color;
  $form-icon-hover-color:                         $global-color;

  $form-range-thumb-height:                       15px;
  $form-range-thumb-border-radius:                500px;
  $form-range-thumb-background:                   $global-color;

  $form-range-track-height:                       3px;
  $form-range-track-background:                   $global-muted-background;
  $form-range-track-focus-background:             $global-muted-background;

  $internal-form-select-image:                    url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" x="0px" y="0px" width="14" height="14" viewBox="0 0 451.847 451.847" xml:space="preserve" fill="black"><path d="M225.923,354.706c-8.098,0-16.195-3.092-22.369-9.263L9.27,151.157c-12.359-12.359-12.359-32.397,0-44.751c12.354-12.354,32.388-12.354,44.748,0l171.905,171.915l171.906-171.909c12.359-12.354,32.391-12.354,44.744,0c12.365,12.354,12.365,32.392,0,44.751L248.292,345.449C242.115,351.621,234.018,354.706,225.923,354.706z"/></svg>');
  $internal-form-radio-image:                     url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M10 8c0 1.105-0.895 2-2 2s-2-0.895-2-2c0-1.105 0.895-2 2-2s2 0.895 2 2z"></path></svg>');
  $internal-form-checkbox-image:                  url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="22" height="16" viewBox="0 0 22 16" stroke="black"><path fill="none" stroke="inherit" stroke-width="1.8824" stroke-miterlimit="50" stroke-linecap="butt" stroke-linejoin="miter" d="M20.028 2.353l-12.028 12.028-6.381-6.381"></path></svg>');
  $internal-form-checkbox-indeterminate-image:    url('data:image/svg+xml;utf8,<svg version="1.1" xmlns="http://www.w3.org/2000/svg" width="16" height="16" viewBox="0 0 16 16" fill="black"><path d="M3 8h10v1h-10v-1z"></path></svg>');


input,
button,
select,
optgroup,
textarea {
  margin: 0;
  font-family: inherit;
  font-size: inherit;
  line-height: inherit;
}

button,
select {
  text-transform: none;
  cursor: pointer;
}

[type="number"]::-webkit-inner-spin-button,
[type="number"]::-webkit-outer-spin-button {
  height: auto;
}

[type="radio"]:not(:disabled),
[type="checkbox"]:not(:disabled) {
  cursor: pointer;
}

::-webkit-file-upload-button {
  font: inherit;
  -webkit-appearance: button;
}

textarea {
  overflow: auto;
  resize: vertical;
}

fieldset {
  min-width: 0;
  padding: 0;
  margin: 0;
  border: 0;
}

legend {
  display: block;
  width: 100%;
  max-width: 100%;
  padding: 0;
  margin-bottom: .5rem;
  font-size: $form-legend-font-size;
  line-height: $form-legend-line-height;
  color: inherit;
  white-space: normal;
}

progress {
  vertical-align: baseline;
}

output {
  display: inline-block;
}

summary {
  display: list-item;
  cursor: pointer;
}

::-webkit-datetime-edit,
::-webkit-inner-spin-button {
  height: auto;
}

input:not([type]),
input[type=date],
input[type=datetime-local],
input[type=email],
input[type=file],
input[type=number],
input[type=password],
input[type=search],
input[type=tel],
input[type="month"],
input[type=week],
input[type=text],
input[type=time],
input[type=url],
textarea,
select {
  display: inline-flex;
  align-items: center;
  justify-content: flex-start;
  width: 100%;
  max-width: 100%;
  margin: 0;
  outline: 0;
  -webkit-appearance: none;
  -moz-appearance: none;
  padding: $form-padding;
  min-height: $form-height;
  background: $form-background;
  border: $global-border-width solid $global-color;
  color: $form-color;
  box-shadow: 0 0 0 0 transparent inset;
  border-radius: 0;
}

::placeholder {
  color: $form-placeholder-color;
  opacity: 1;
}

input:not([type]):focus,
input[type=date]:focus,
input[type=datetime-local]:focus,
input[type=email]:focus,
input[type=file]:focus,
input[type=number]:focus,
input[type=password]:focus,
input[type=search]:focus,
input[type=tel]:focus,
input[type="month"]:focus,
input[type=week]:focus,
input[type=text]:focus,
input[type=time]:focus,
input[type=url]:focus,
textarea:focus,
select:focus {
  background: $form-focus-background;
  color: $form-focus-color;
}

input:not([type])[disabled],
input[type=date][disabled],
input[type=datetime-local][disabled],
input[type=email][disabled],
input[type=file][disabled],
input[type=number][disabled],
input[type=password][disabled],
input[type=search][disabled],
input[type=tel][disabled],
input[type="month"][disabled],
input[type=week][disabled],
input[type=text][disabled],
input[type=time][disabled],
input[type=url][disabled],
textarea:disabled,
select:disabled {
  pointer-events: none;
  opacity: .45;
  background: $form-disabled-background;
  color: $form-disabled-color;
}

select:not([multiple]):not([size]) {
  padding-right: $form-select-padding-right;
  padding-left: 8px;
  background-image: $internal-form-select-image;
  background-repeat: no-repeat;
  background-position: 96% 50%
}

select:not([multiple]):not([size])::-ms-expand {
  display: none
}

select:not([multiple]):not([size]) option {
  color: $form-select-option-color
}

select:not([multiple]):not([size]):disabled { 
  background-image: $internal-form-select-image;
}

input[type="radio"] {
  border: none;
  display: inline-block;
  height: $form-radio-size;
  width: $form-radio-size;
  overflow: hidden;
  margin-top: $form-radio-margin-top;
  vertical-align: middle;
  background-color: $form-radio-background;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 50% 50%;
}

input[type="checkbox"] {
  border: 1px solid $global-muted-color;
  display: inline-block;
  height: $form-radio-size;
  width: $form-radio-size;
  min-width: $form-radio-size;
  overflow: hidden;
  margin-top: $form-radio-margin-top;
  vertical-align: middle;
  background-color: $form-radio-background;
  background-repeat: no-repeat;
  background-size: 16px;
  background-position: 50% 50%;
}

input[type="checkbox"] {
  -webkit-appearance: none;
  -moz-appearance: none;
}
input[type="radio"] {
  border-radius: 50%
}

input[type="radio"]:checked {
  background-image: $internal-form-radio-image;
}

input[type="checkbox"]:checked {
  background-image: $internal-form-checkbox-image;
}

input[type="checkbox"]:indeterminate {
  background-image: $internal-form-radio-image;
}

input[type="radio"]:focus,
input[type="checkbox"]:focus {
  outline: none;
}

input[type="radio"]:checked,
input[type="checkbox"]:checked,
input[type="checkbox"]:indeterminate {
  background-color: $form-radio-checked-background;
}

input[type="radio"]:checked:focus,
.uk-checkbox:checked:focus,
.uk-checkbox:indeterminate:focus {
  background-color: $form-radio-checked-focus-background;
}

input[type="radio"]:disabled:checked {
  background-image: $internal-form-radio-image;
}

input[type="checkbox"]:disabled:checked {
  background-image: $internal-form-checkbox-image;
}

input[type="checkbox"]:disabled:indeterminate {
  background-image: $internal-form-checkbox-indeterminate-image;
}

input[type="radio"]:disabled,
input[type="checkbox"]:disabled {
  background-color: $form-radio-disabled-background;
}

input[type="range"] {
  box-sizing: border-box;
  margin: 0;
  vertical-align: middle;
  max-width: 100%;
  width: 100%;
  -webkit-appearance: none;
  background: transparent;
  padding: 0;
}

input[type="range"]:focus { outline: none; }
input[type="range"]::-moz-focus-outer { border: none; }

input[type="range"]::-ms-track {
  height: $form-range-thumb-height;
  background: transparent;
  border-color: transparent;
  color: transparent;
}

input[type="range"]:not(:disabled)::-webkit-slider-thumb { cursor: pointer; }
input[type="range"]:not(:disabled)::-moz-range-thumb { cursor: pointer; }
input[type="range"]:not(:disabled)::-ms-thumb { cursor: pointer; }

input[type="range"]::-webkit-slider-thumb {
  -webkit-appearance: none;
  margin-top: -8px;
  height: $form-range-thumb-height;
  width: $form-range-thumb-height;
  border-radius: $form-range-thumb-border-radius;
  background: $form-range-thumb-background;
}

input[type="range"]::-moz-range-thumb {
  border: none;
  height: $form-range-thumb-height;
  width: $form-range-thumb-height;
  border-radius: $form-range-thumb-border-radius;
  background: $form-range-thumb-background;
}

input[type="range"]::-ms-thumb {
  margin-top: 0;
}

input[type="range"]::-ms-thumb {
  border: none;
  height: $form-range-thumb-height;
  width: $form-range-thumb-height;
  border-radius: $form-range-thumb-border-radius;
  background: $form-range-thumb-background;
}

input[type="range"]::-ms-tooltip { display: none; }

input[type="range"]::-webkit-slider-runnable-track {
  height: $form-range-track-height;
  background: $form-range-track-background;
}

input[type="range"]:focus::-webkit-slider-runnable-track,
input[type="range"]:active::-webkit-slider-runnable-track {
  background: $form-range-track-focus-background;
}

input[type="range"]::-moz-range-track {
  height: $form-range-track-height;
  background: $form-range-track-background;
}

input[type="range"]:focus::-moz-range-track {
  background: $form-range-track-focus-background;
}

input[type="range"]::-ms-fill-lower,
input[type="range"]::-ms-fill-upper {
  height: $form-range-track-height;
  background: $form-range-track-background;
}

input[type="range"]:focus::-ms-fill-lower,
input[type="range"]:focus::-ms-fill-upper {
  background: $form-range-track-focus-background;
}

label {
  margin: 0.8rem 0;
  display: inline-block;
}

label.is-invalid {
  color: $form-invalid-color;
}

label.is-valid {
  color: $form-valid-color;
}

input.is-invalid,
input.is-invalid:focus {
  color: $form-invalid-color;
  border-color: $form-invalid-color;
}

input.is-valid,
input.is-valid:focus {
  color: $form-valid-color;
  border-color: $form-valid-color;
}

.form-control {
  margin-bottom: $form-control-margin-bottom;
}

.form-icon {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  width: $form-icon-width;
  display: inline-flex;
  justify-content: center;
  align-items: center;
  color: $form-icon-color;
}

.form-icon:hover { color: $form-icon-hover-color; }

.form-icon:not(a):not(button):not(input) { pointer-events: none; }

.form-icon:not(.form-icon-flip) ~ input { padding-left: $form-icon-width !important; }

.form-icon-flip {
  right: 0;
  left: auto;
}

.form-icon-flip ~ input { 
  padding-right: $form-icon-width !important; 
}

.get-in-touch span.asterix {
  color: $global-warning-background;
}
.get-in-touch img.ajax-loader {
	margin-right: -16px;
}

.get-in-touch input.wpcf7-not-valid {
  border: 1px solid $global-warning-background;
}

.get-in-touch span.wpcf7-not-valid-tip {
	padding: 6px 0;
  color: $global-warning-background;
  font-size: 14px;
}

.get-in-touch div.wpcf7-validation-errors {
	border: none;
  color: $global-warning-background;
  text-align: center;
}

.get-in-touch div.wpcf7-mail-sent-ok {
	border: none;
  color: $global-success-background;
}

.get-in-touch div.wpcf7-validation-errors, 
  .get-in-touch div.wpcf7-mail-sent-ok {
  margin: 4px auto;
      width: 300px;
}

.grecaptcha-badge {
  position: absolute;
  z-index: -100;
}