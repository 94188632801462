section.calendar {
    margin-bottom: 4rem;

    .container {

        .wpfc-calendar-wrapper {
            font-family: $global-font-family;

            button {
                min-width: auto;
                min-height: auto;

                &.fc-state-default {
                    border-radius: 2px;
                    background-color: transparent;
                    background-image: none;
                    font-family: $global-font-family;
                    font-size: 14px !important;
                    border-color: #ccc;
                }
                &.fc-state-active, 
                &.fc-state-down {
                    border-color: rgba(0, 57, 138, 0.3);
                    background-color: transparent;
                    background-image: none;
                    box-shadow: none;
                }

                &.fc-today-button {
                    color: $global-secondary-background;
                }

                &.fc-prev-button,
                &.fc-next-button {
                    span.fc-icon {
                        border-radius: 50%;
                        background-color: $global-link-hover-color;
                        overflow: visible;
                        height: 18px;
                        width: 18px;

                        &:after {
                            position: absolute;
                            top: 2px;
                            left: 1px;
                            display: inline-block;
                            font-family: icomoon!important;
                            font-weight: 400;
                            font-size: 17px;
                            margin: 0;
                            color: #fff;
                        }

                        &.fc-icon-left-single-arrow:after {
                            content: "\e91a" !important;
                        }

                        &.fc-icon-right-single-arrow:after {
                            content: "\e918" !important;
                        }
                    }
                }
            }

            h2 {
                font-size: 18px !important;
            }

            .fc-head-container {
                background: $global-link-color;

                .fc-day-header {
                    text-transform: uppercase;
                    font-size: 15px !important;
                    font-family: $global-font-family;
                    color: $global-inverse-color;
                    padding: 8px;
                }
            }

            .fc-event-time,
            .fc-time {
                display: none;
            }

            .fc-day {
                background: linear-gradient(180deg, #FFFFFF 0%, #EEEEEE 100%);
                border: 1px solid rgba(0, 57, 138, 0.3);
            }

            a.fc-event {
                background-color: $global-primary-background!important;

                & * {
                    font-size: 10px !important;
                }
            }

            .fc-unthemed .fc-today {
                background: transparent;
            }
        }
    }
}

@media screen and (max-width: 567px) {
    section.calendar {

        .container {
            .wpfc-calendar-wrapper {

                .fc-toolbar {
                    display: flex;
                    flex-direction: column;
                    align-items: center;

                    .fc-center {
                        order: 0;
                        margin-bottom: 1rem;
                    }

                    .fc-left {
                        order: 1;
                        margin-bottom: 1rem;
                    }

                    .fc-right {
                        order: 2;
                        margin-bottom: 1rem;
                    }
                }

               

                

               
            }
            
        }
    }
}