.section-title,
#reply-title {
    margin-bottom: 2rem;
    text-align: center;
    overflow: hidden;

    p, h1, h2, h3, h4, h5, h6 {
        display: inline-block;
        position: relative;
        font-family: $montserrat-font-family;
        font-size: 36px;
        color: $global-primary-background;
        font-weight: 600;
        width: auto;
        
        &::before,
        &::after {
            content: "";
            position: absolute;
            width: 100px;
            height: 1px;
            background-color: $global-primary-background;
        }

        &::before {
            left: -120px;
            top: 50%;
        }

        &::after {
            right: -120px;
            top: 50%;
        }
    }
}

@media screen and (max-width: 576px) {
    .section-title,
    #reply-title {
        p, h1, h2, h3, h4, h5, h6 {
            max-width: 270px;

            &::before,
            &::after {
                width: 40px;
            }

            &::before {
                left: -50px;
            }

            &::after {
                right: -50px;
            }
        }
    }
}

@media screen and (max-width: 320px) {
    .section-title {
        p, h1, h2, h3, h4, h5, h6 {
            max-width: 160px;
            font-size: 1.6rem;
        }
    }
}

.separator {
    height: 40px;
}