section.page-404 {
    .page-404-layout {
        display: flex;
        align-items: center;
        justify-content: space-around;
        padding: 4rem 0;
        flex-wrap: wrap;

        .item-logo {
            width: 60%;

            .logo-status {
                color: $global-secondary-background;
                font-weight: bold;
                font-size: 16rem;
                font-family: $montserrat-font-family;
            }
        }

        .item-return {
            width: 40%;
            padding: 2rem;

            .return-title {
                font-size: 36px;
                font-weight: bold;
                color: $global-primary-background;
                font-family: $montserrat-font-family;
                margin: 2rem 0;
            }

            .return-text {
                font-size: 24px;
                text-transform: uppercase;
                font-weight: bold;
                margin: 2rem 0;
            }

            .button {
                &:hover,
                &:active,
                &:focus {
                    background-color: $global-secondary-background;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    section.page-404 {
        .page-404-layout {
            flex-direction: column;
    
            .item-logo {
                width: 100%;
    
                .logo-status {
                    text-align: center;
                    font-size: 10rem;
                }
            }
    
            .item-return {
                width: 100%;
                text-align: center;
            }
        }
    }
}