.single-cemetery {
    section.single-cemetry {
        .single-cemetry-layout {
            .item-map {
                position: relative;

                .map-info {
                    position: absolute;
                    top: 0;
                    right: 0;
                    display: inline-block;
                    height: 100%;
                    width: 300px;
                    background-color: $global-primary-background;
                    padding: 2rem;

                    .info-item {
                        position: relative;
                        margin-bottom: 16px;
                        padding-left: 46px;
                        color: $global-inverse-color;

                        .item-title {
                            font-size: 18px;
                            color: $global-secondary-background;
                        }

                        a {
                            color: $global-inverse-color;

                            &:hover,
                            &:active,
                            &:focus {
                                color: $global-link-hover-color;
                            }
                        }

                        span {
                            position: absolute;
                            left: 0;
                            top: 8px;
                            font-size: 30px;
                        }

                    }
                }
            }

            .item-gallery {
                .ngg-gallery-thumbnail {
                    border: none;
                }
            }

            .item-info {
                .content-layout {
                    display: flex;

                    .item-text {
                        margin-right: 1rem;

                        .su-spoiler-style-default > .su-spoiler-title {
                            padding: 0;
                            font-size: 18px;

                            .su-spoiler-icon {
                                display: none;
                            }

                            &:hover,
                            &:active,
                            &:focus {
                                cursor: pointer;
                                color: $global-link-hover-color;
                                outline: none;
                            }
                        }
                    }

                    .item-document {
                        max-width: 360px;
                        flex-shrink: 0;

                        a {
                            color: transparent;
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .single-cemetery {
        section.single-cemetry {
            .single-cemetry-layout {
                .item-map {

                    .map-info {
                        position: static;
                        height: auto;
                        width: 100%;
                    }
                }
                .item-gallery {

                }

                .item-info {
                    .content-layout {
                        flex-direction: column;
                        align-items: center;

                        .item-text {
                            margin-right: 0;
                        }
                    }
                }
            }
        }
    }
}