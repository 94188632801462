.page-template-template-synagogues {

    .synagogues-map {

    }

    .synagogues-list {
        .synagogues-list-layout {
            padding: 3rem 0;
            
            .list-layout-item {
                border: 1px solid #00398A;
                padding: 30px 90px;
                margin-bottom: 24px;
                display: flex;
                flex-direction: column;

                .item-title {
                    display: inline-block;
                    font-size: 16px;
                    color: $global-primary-background;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                }

                .item-description {
                    font-size: 14px;
                    font-family: $global-font-family;
                    color: $global-color;
                    margin-top: 0;
                    position: relative;
                    padding-left: 24px;

                    &:before {
                        content: "\e914";
                        font-family: 'icomoon' !important;
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 14px;
                        border: 1px solid $global-link-color;
                        color: $global-link-color;
                        border-radius: 50%;
                        width: 20px;
                        height: 20px;
                        text-align: center;
                    }
                }

                .item-phone {
                    color: $global-color;
                    position: relative;
                    padding-left: 24px;

                    &:before {
                        content: "\e909";
                        color: #d6a33a;
                        font-family: 'icomoon' !important;
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 14px;
                    }

                    &:hover,
                    &:active,
                    &:focus {
                        color: $global-link-hover-color;
                    }
                }

                .item-address {
                    color: $global-color;
                    position: relative;
                    padding-left: 24px;

                    &:before {
                        content: "\e907";
                        color: #d6a33a;
                        font-family: 'icomoon' !important;
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 768px) {
    .page-template-template-synagogues {

        .synagogues-map {

        }

        .synagogues-list {
            .synagogues-list-layout {
                
                .list-layout-item {
                    padding: 20px 40px;
                }
            }
        }
    }
}
