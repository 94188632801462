section.history {
  .history-layout {
    p {
      margin: 0;
    }

    #tabs {
      margin-bottom: 4rem;

      .tabs-header {
        display: flex;
        align-items: center;
        justify-content: space-around;

        background: transparent;
        padding: 1rem;

        > li {
          margin: 0;

          a {
            color: $global-muted-color;
            text-transform: capitalize;
            font-size: 24px;
            font-family: $montserrat-font-family;
            font-weight: 600;

            &:hover,
            &:active,
            &:focus {
              color: $global-link-hover-color;
              border: none;
              outline: none;
              text-decoration: underline;
            }
          }

          &.ui-tabs-active {
            border: none;

            a {
              color: #023278;
              cursor: default;
              border: none;
              outline: none;
              text-decoration: underline;
            }
          }
        }
      }

      .tabs-list {
        position: relative;
        z-index: 1;

        background-image: url(/wp-content/themes/steeles-new/img/bg/history.png);
        background-position: center center;
        background-repeat: no-repeat;
        background-size: cover;

        padding: 4rem 0;

        &:before {
          position: absolute;
          z-index: -1;
          content: "";
          left: 0;
          width: 100%;
          top: 0;
          height: 100%;
          display: inline-block;
          background-color: rgba(0, 57, 138, 0.2);
        }

        .tabs-item {
          background: transparent;
          border: none;

          .tabs-item-layout {
            display: flex;
            flex-wrap: wrap;
            justify-content: space-around;
          }
        }
      }
    }

    .item-action.action-past {
      text-align: center;

      .button {
        background-color: $global-secondary-background;
        color: $global-inverse-color;

        &:hover,
        &:active,
        &:focus {
          background-color: $global-link-hover-color;
        }
      }
    }
  }
}

.funeral-services {
  position: relative;
  display: flex;
  background-color: $global-inverse-color;

  margin: 4px;
  margin-bottom: 8px;
  padding: 1rem;
  outline: 2px solid rgba(0, 57, 138, 0.3);
  outline-offset: -2px;

  max-width: 270px;
  max-height: 200px;
  min-width: 270px;
  min-height: 170px;

  .action-map {
    position: absolute;
    right: 0;
    bottom: 0;
    width: 90px;
    height: 57px;
    z-index: 1;
  }

  .services-layout {
    display: flex;
    flex-direction: column;
    justify-content: space-between;

    .details-name {
      color: $global-primary-background;
      font-size: 20px;
      position: relative;
      width: max-content;
      font-family: $global-font-family;
      width: 100%;

      &:after {
        position: absolute;
        bottom: -2px;
        left: 0;
        content: "";
        display: inline-block;
        height: 2px;
        width: 60px;
        background-color: $global-link-color;
      }
    }

    .details-wrap {
      display: flex;
      font-family: $global-font-family;

      padding: 8px 0;

      .details-img {
        height: 64px;
        width: 64px;
        margin-right: 8px;
        overflow: hidden;

        figure {
          background-position: center center;
          background-repeat: no-repeat;
          background-size: cover;
          height: 100%;
          width: 100%;
        }
      }

      .details-info {
        display: flex;
        flex-direction: column;

        .info-funeral,
        .info-burial {
          color: $global-primary-background;
          font-size: 1rem;
          font-weight: bold;
        }
      }
    }

    .view-more {
      a {
        position: relative;
        color: $global-color;
        font-size: 18px;
        font-family: $global-font-family;

        &:after {
          position: absolute;
          content: "\e901";
          font-family: "icomoon";
          right: -32px;
          top: 50%;
          transform: translateY(-50%);
          font-size: 10px;
          color: $global-link-color;
        }

        &:hover,
        &:active,
        &:focus {
          color: $global-link-hover-color;
          text-decoration: underline;
        }
      }
    }
  }
}

@media screen and (max-width: 768px) {
  section.history {
    .history-layout {
      #tabs {
        .tabs-header {
          > li a {
            padding: 0 6px;
            font-size: 18px;
          }
        }

        .tabs-list {
          .tabs-item {
            .tabs-item-layout {
              .funeral-services:nth-child(n + 5) {
                display: none;
              }
            }
          }
        }
      }
    }
  }
}
