section.shabbat {
    background-color: $global-primary-background;
    margin-bottom: 4rem;

    .shabbat-layout {
        padding: 1.6rem 4rem;

        *:not(a) {
            color: $global-inverse-color;
        }

        .hebcal-container > div {
            display: flex;
            flex-wrap: wrap;
            justify-content: center;
            align-items: center;

            > h3,
            > ul {
                min-width: 300px;
                max-width: 500px;
                margin: 8px 0;
            }

            > h3 {
                font-family: $montserrat-font-family;
                font-weight: bold;
                color: $global-link-color;
                font-size: 24px;
                padding-left: 60px;
                position: relative;

                &:before {
                    content: "\e903";
                    font-family: 'icomoon';
                    font-size: 4rem;
                    position: absolute;
                    left: 0;
                    bottom: 0;
                    color: $global-inverse-color;
                }
            }

            .hebcal-results {
                > li {
                    position: relative;
                    padding-left: 12px;
                    font-size: 18px;

                    &:before {
                        content: "\e902";
                        font-family: 'icomoon';
                        position: absolute;
                        left: 0;
                        top: 6px;
                        color: $global-link-color;
                        font-size: 10px;
                    }

                    a {
                        text-decoration: underline;
                    }
                }
            }

            .copyright {
                display: none;
            }
        }
    }
}