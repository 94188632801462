.container {
  max-width: 1200px;
  padding-left: 1rem;
  padding-right: 1rem;
  margin-left: auto;
  margin-right: auto;
}

.container .container {
  padding-left: 0;
  padding-right: 0
}

.container-expand {
  max-width: none
}