header {
    background-color: $global-primary-background;

    * {
        color: $global-inverse-color;
    }
    .head_content {
        position: relative;
        z-index: 2;
        color: $global-inverse-color;

        .item-top {
            display: flex;
            align-items: center;
            justify-content: space-between;

            padding: 1rem 0;  
    
            .head_logo a {
                display: inline-block;
            }
            .head_logo img{
                width: 300px;
                height: 100%;
            }

            .header-weather {
                .simple-weather {
                    width: 100%;

                    .simple-weather-widget {
                        padding: 0 !important;

                        .simple-weather-table {
                            display: flex;
                            align-items: center;
                            justify-content: space-between;
                            margin: 6px 0;

                            > div {
                                display: inline-block;
                                width: auto;

                                div {
                                    margin: 0;
                                    padding: 2px;
                                }

                                i {
                                    margin: 0;
                                    color: $global-link-color;
                                }
                            }
                        }
                    }
                }
            }

            .header-contact-no {
                .contact-info p.phone-no {
                    font-size: 28px;
                    line-height: 35px;

                    a {
                        color: $global-link-color;
                    }

                    a:hover,
                    a:active,
                    a:focus {
                        color: $global-link-hover-color;
                    }
                }
            }
        }
    
        hr {
            border-top: 4px double $global-inverse-color;
        }

        .main_menu {

            ul {
                padding: 0;
            }

            a:not([href]) {
                cursor: default;

                &:hover,
                &:focus,
                &:active {
                    cursor: default;
                }
            }

            .main-menu-layout {
                display: flex;
                align-items: center;
                justify-content: space-between;
        
                height: 60px;
    
                > ul {
                    display: flex;
                    align-items: center;
    
                    width: 100%;
                    height: 100%;
                    margin: 0;
    
                    > li {
                        position: relative;
                        margin-right: 20px;
                        height: 100%;
    
                        display: flex;
                        align-items: center;
    
                        > a {
                            display: inline-block;
                            color: $global-inverse-color;
                            font-size: 15px;
                            text-transform: uppercase;

                            &:not(:last-child) {
                                margin-right: 30px;
                            }
                        }
    
                        .sub-menu {
                            position: absolute;
                            display: none;
                            top: 100%;
                            min-width: 250px;
                            background-color: #f9f7f7;
    
                            li {
                                position: relative;

                                a {
                                    font-size: 16px;
                                    width: 100%;
                                    display: inline-block;
                                    padding: 10px 16px;
                                    color: $global-color;
        
                                    &:hover,
                                    &:focus,
                                    &:active {
                                        background-color:#eaeaea;
                                    }
                                }
    
                                .sub-menu {
                                    left: 100%;
                                    top: 0;
                                }
                            }
                        }
    
                        .icon-arrow-down {
                            position: absolute;
                            right: 0px;
                            top: 50%;
                            transform: translateY(-50%);
                            font-size: 8px;
                        }
                    }
    
                    > li:hover {
                        &:after {
                            content: "";
                            display: inline-block;
                            height: 0px;
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            background-color: $global-link-color;
                        }

                        &:hover:after,
                        &:focus:after,
                        &:active:after {
                            height: 2px;
                        }
                    }

                    > li:hover > a,
                    > li:hover > .icon-arrow-down {
                        color: $global-link-color;
                    }

                    > li.current-menu-parent,
                    > li.current-menu-item,
                    > li.current-menu-ancestor {
                        &:after {
                            content: "";
                            display: inline-block;
                            height: 2px;
                            width: 100%;
                            position: absolute;
                            bottom: 0;
                            left: 0;
                            background-color: $global-link-color;
                        }

                        > a,
                        .icon-arrow-down {
                            color: $global-link-color;
                        }
                    }
                }
    
                #searchform {
                    position: relative;
        
                    input.search {
                        color: $global-inverse-color;
                        border: 1px solid $global-inverse-color;
                        min-height: 40px;
                        padding-left: 26px;
            
                        &::placeholder {
                            color: $global-inverse-color;
                            transition: 0.2s;
                        }

                        &:hover,
                        &:active,
                        &:focus {
                            padding-right: 26px;
                            padding-left: 14px;
                        }

                        &:hover::placeholder,
                        &:active::placeholder,
                        &:focus::placeholder {
                            color: transparent;
                            transition: 0.2s;
                        }
                    }

                    .icon-search {
                        position: absolute;
                        left: 8px;
                        top: 14px;
                    }

                    &:hover .icon-search,
                    &:active .icon-search,
                    &:visited .icon-search,
                    &:focus-within .icon-search {
                        left: auto;
                        right: 8px;
                    }
                }
            }
        }

        .mobile-btn {
            display: none;
            justify-content: space-between;
            align-items: center;
            padding: 1.2rem 0.6rem;
            cursor: pointer;
            font-size: 2rem;

            p {
                margin: 0;
            }
        }

        .mobile-menu {
            display: none;
        }
    }
}

@media screen and (max-width: 1000px) {
    header {

        .head_content {
            .item-top {
                flex-direction: column;

                .head_logo {
                    padding: 1rem;
                }
        
                .header-weather {
                    display: none;
                }
    
                .header-contact-no {
                    text-align: center;
                    padding-top: 1rem;
                    width: 100%;
                    border-top: 4px double #fff;
                }
            }

            hr {
                display: none;
            }

            .item-nav {
                background-color: $global-secondary-background;

                .mobile-btn {
                    display: flex;

                    .burger-btn {
                        display: inline-block;

                        .bar1, 
                        .bar2,
                        .bar3 {
                            width: 30px;
                            height: 4px;
                            background-color: $global-inverse-color;
                            margin: 4px 0;
                            transition: 0.3s;
                        }

                        &.change {
                            .bar1 {
                                -webkit-transform: rotate(-45deg) translate(-4px, 4px);
                                transform: rotate(-45deg) translate(-4px, 4px);
                            }

                            .bar2 {opacity: 0;}

                            .bar3 {
                                -webkit-transform: rotate(45deg) translate(-8px, -8px);
                                transform: rotate(45deg) translate(-8px, -8px);
                            }
                        }
                    }
                }

                .main_menu {
                    display: none;
                }
                .mobile-menu {
                    display: none;
        
                    > ul {
                        flex-direction: column;
                        padding: 0;
    
                        li {
                            margin: 0;
    
                            a {
                                margin: 0;
                                padding: 8px;
                                width: 100%;
                                display: inline-block;
                                font-size: 16px;
                            }
    
                            .sub-menu {
                                padding: 0;
                                display: none;
                                padding-left: 24px;
                            }
                        }

                        > li > a {
                            font-weight: bold;
                            font-size: 18px;
                        }
                    }
                }
            }
        }
    }
}