.single-condolence {

    .single-condolence-profile {
        margin-bottom: 4rem;

        .profile-description {
            display: flex;
            background-color: $global-muted-background;
            padding: 40px;
            margin-bottom: 2rem;

            .description-photo {
                width: 230px;
                height: 230px;
                flex-shrink: 0;

                figure {
                    background-position: center center;
                    background-repeat: no-repeat;
                    background-size: cover;
                    height: 100%;
                    width: 100%;
                }
            }

            .post-content {
                flex-shrink: 1;
                padding: 0 30px;
                font-size: 18px;
                line-height: 28px;
            }
        }

        .profile-details {
            .profile-details-layout {
                .item-row {
                    margin: 8px 0;
                    display: flex;
                    font-size: 18px;

                    .title {
                        display: inline-block;
                        min-width: 300px;
                        font-weight: bold;
                    }
                }
            }
        }

        .profile-map {

            .profile-map-layout {
                display: flex;
                justify-content: flex-start;
                align-items: flex-start;
                flex-wrap: wrap;
                padding: 1rem 0;

                .layout-item {
                    display: flex;
                    flex-direction: column;
                    margin: 1.5rem;
                    max-width: 260px;
                    font-size: 18px;
                    &:first-child{
                        margin-left: 0;
                    }
                    &:last-child{
                        margin-right: 0;
                    }
                    p {
                        margin: 0
                    }

                    .item-title {
                        margin-bottom: 8px;
                    }

                    .item-map {
                        height: 200px;
                        width: 270px;
                        margin-bottom: 8px;
                    }

                    .item-more {
                        text-decoration: underline;
                        color: $global-primary-background;
                        text-transform: capitalize;
                        font-weight: 600;

                        &:hover,
                        &:active,
                        &:focus {
                            color: $global-link-hover-color;
                        }
                    }
                }
            }
        }
    }

    .item-form {
        margin-bottom: 2rem;

        #commentform {
            display: flex;
            flex-wrap: wrap;
            align-items: center;
            justify-content: space-between;

            max-width: 770px;
            margin: 0 auto;

            .comment-notes {
            text-align: center;
            width: 100%;
            }

            .comment-form-comment {
                width: 100%;

                textarea {
                    min-height: 110px;
                    max-height: 120px;
                }
            }

            .comment-form-author {
                width: 49%;
            }

            .comment-form-email {
                width: 49%;
            }

            .comment-form-cookies-consent {
                display: flex;
                align-items: center;
                justify-content: center;
                width: 100%;

                input[type=checkbox] {
                    margin-right: 12px;
                }
            }

            .form-submit {
                width: 100%;
                text-align: center;
            }

            span.required {
                color: $global-warning-background;
            }
        }
    }

    .comments {
        padding: 3rem 0;

        .comments-layout {
            .comment-single {
                border: 1px solid #00398A;
                padding: 30px 90px;
                margin-bottom: 24px;

                .comment-content-layout {
                    .comment-time {
                        display: block;
                        font-size: 12px;
                        font-family: $global-font-family;
                        color: rgb(158, 157, 157);
                        margin-bottom: 8px;
                    }

                    .title {
                        display: block;
                        font-size: 16px;
                        color: $global-primary-background;
                        text-transform: uppercase;
                        margin-bottom: 8px;
                    }

                    .description {
                        font-size: 14px;
                        font-family: $global-font-family;
                        color: $global-color;
                    }

                    .links {
                        display: none;
                    }
                }
            }
        }
    }
}
@media screen and (max-width: 1024px){
    .single-condolence{
        .single-condolence-profile{
            .profile-map{
                .profile-map-layout{
                    .layout-item{
                        &:last-child{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    } 
}

@media screen and (max-width: 768px) {
    .single-condolence {
        .single-condolence-profile {
            .profile-description {
                flex-direction: column;
                align-items: center;

                .post-content {
                    font-size: 16px;
                    line-height: 24px;
                }

                .description-photo {
                    margin-bottom: 1rem;
                }
            }

            .profile-details {
                .profile-details-layout {
                    .item-row {
                        flex-direction: column;
                        margin-bottom: 1rem;

                        .title {
                            min-width: none;
                        }
                    }
                }
            }

            .profile-map{
                .profile-map-layout{
                    .layout-item{
                        &:nth-child(3){
                            margin-left: 0;
                        }
                        &:last-child{
                            margin-left: 1.5rem;
                        }
                    }
                }
            }
        }

        .comments {
            .comments-layout {
                .comment-single {
                    padding: 20px 40px;
                }
            }
        }
    }
}
@media screen and (max-width: 576){
    .single-condolence{
        .single-condolence-profile{
            .profile-map{
                .profile-map-layout{
                    .layout-item{
                        &:nth-child(2),
                        &:nth-child(3),
                        &:last-child{
                            margin-left: 0;
                        }
                    }
                }
            }
        }
    } 
}
