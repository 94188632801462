.single-smcnewsletters {
    .content {
        text-align: center;

        h1 {
            margin-top: 80px;
            font-size: 30px;
        }

        h2 {
            margin-top: 30px;
        }

        a {
            color: #183678;
            text-decoration: underline;
        }
    }
}
