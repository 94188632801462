#donation-form,
#contact-us {
    width: 700px;
    max-width: 100%;
    padding: 2rem;
    box-sizing: border-box !important;

    .gsection_description {
        margin: 1rem 0;
        padding: 0;
        text-align: center;
        font-size: 14px;
    }

    .gform_wrapper {
        max-width: none;

        div.validation_error {
            color: $global-warning-background;  
        }

        .gsection {
            border-bottom: none;
            margin: 2rem 0;

            .gsection_title {
                text-align: center;
                width: auto;
            }
        }

        .top_label .gfield_label, 
        .ginput_complex label {
            font-weight: normal;
            margin-bottom: 1rem;
        }

        li.gfield {
            margin-bottom: 18px;
        }

        li.gfield.gfield_error {
            background-color: transparent;
            padding: 0 !important;
            border-top: none;
            border-bottom: none;

            label {
                color: $global-color;  
            }

            input,
            textarea {
                border: 1px solid $global-warning-background;
            }

            &.gfield_contains_required {
                padding-left: 0 !important;
            }

            &.gfield_contains_required.gfield_creditcard_warning {
                background-color: transparent;
                padding: 0 !important;
                border-top: none;
                border-bottom: none;
            }

            .ginput_complex .ginput_left,
            .ginput_complex .ginput_right {
                width: 46%;
            }
        }

        .validation_message,
        .gfield_required {
            color: $global-warning-background; 
            font-size: 14px;
            font-weight: normal; 
            padding: 0 !important;
        }

        ul.gfield_radio {
            display: flex;
            flex-wrap: wrap;
            border-bottom: 1px solid #ccc;
            padding-bottom: 1rem;

            li {
                min-width: 180px;

                input {
                    height: auto !important;
                    width: 16px !important;
                }
            }
        }

        .top_label .gfield_error {
            width: 100%;

            .ginput_container {
                max-width: none;
            }

            &.gf_left_half,
            &.gf_right_half {
                width: 46% !important;
            }
        }

        input:not([type=submit]),
        select {
            width: 100% !important;
        }

        .gform_footer {
            text-align: center;
        }
    }

    .section-title {
        .gsection_title {
            font-size: 2.4rem;
        }
    }
}