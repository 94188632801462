footer {
    background-color: $global-primary-background;
    padding: 3rem 0 1rem;

    * {
        color: $global-inverse-color;
    }

    .footer-layout {
        display: flex;
        justify-content: space-between;

        padding: 1rem 0;

        .footer-item {
            max-width: 20%;

            & * {
                font-size: 16px;
                font-family: $global-font-family;
            }

            .h1 {
                margin-bottom: 1rem;
                font-family: $montserrat-font-family;
                font-size: 16px;
                font-weight: 600;
                color: $button-background;
            }

            p {
                margin-bottom: 0.8rem;
            }

            .item-phone,
            .item-fax,
            .item-address {
                position: relative;
                padding-left: 20px;
                margin-bottom: 22px;

                &:before {
                    font-family: 'icomoon' !important;
                    position: absolute;
                    left: 0;
                    top: 0;
                }
            }

            .item-phone:before {
                content: "\e909";
                color: #d6a33a;
            }

            .item-fax:before {
                content: "\e904";
                color: #d6a33a;
            }
            .item-address:before {
                content: "\e907";
                color: #d6a33a;
            }

            .text-subscribe {
                font-size: 12px;
            }

            #subscribe_form {
                input {
                    border-color: $global-inverse-color;
                    min-height: 34px;
                    font-size: 14px;
                    padding: 8px;
                    color: $global-inverse-color;
                    font-weight: normal;
                

                    &::placeholder {
                        color: $global-inverse-color;
                        font-size: 12px;
                    }

                    &#email {
                        position: relative;
                    }
                }

                #btnsubscribe {
                    min-height: 34px;
                    width: 100%;
                    min-width: auto;
                    border: none;
                    border-radius: 0;
                    background-color: $global-link-color;

                    &:hover,
                    &:active,
                    &:focus {
                        background-color: $global-secondary-background;
                    }
                }
            }
        }

        #menu-footer-one {
            padding: 0;

            li {
                position: relative;
                padding-left: 18px;
                margin-bottom: 6px;

                span {
                    position: absolute;
                    display: inline-block;
                    transform: rotate(-90deg);
                    font-size: 6px;
                    top: 6px;
                    left: 0;
                    color: $global-link-color;
                }
            }
        }
    }

    hr {
        border-top: 4px double $global-inverse-color;
    }

    .footer-copyright {
        .copyright {
            display: flex;
            align-items: center;
            text-align: center;

            padding: 1rem 0;

            picture {
                max-height: 26px;

                img {
                    height: 26px;
                }
            }

            p {
                margin: 0 1rem;
                font-size: 12px;
            }
        }
    }
}

@media screen and (max-width: 768px) {
    footer {
        .footer-layout {
            flex-wrap: wrap;

            .footer-item {
                max-width: 50%;
                width: 50%;
                padding: 1rem 0;
            }
        }
    }
}

@media screen and (max-width: 576px) {
    footer {
        .footer-layout {
            .footer-item {
                max-width: 100%;
                width: 100%;

                &:not(:last-child) {
                    border-bottom: 1px solid $global-inverse-color;
                }
            }
        }
    }
}