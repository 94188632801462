.page-template-template-cemeteries {

    .cemetries-map {
        .cemetries-map-layout {
            #mapSemetries {
                height: 460px;
            }
        }
    }

    .cemetries-list {
        .cemetries-list-layout {
            padding: 3rem 0;
            
            .list-layout-item {
                border: 1px solid #00398A;
                padding: 30px 90px;
                margin-bottom: 24px;

                .item-title {
                    display: inline-block;
                    font-size: 16px;
                    color: $global-primary-background;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                }

                .item-description {
                    font-size: 14px;
                    font-family: $global-font-family;
                    color: $global-color;
                    margin-top: 0;
                }

                .item-phone {
                    color: $global-color;
                    position: relative;
                    padding-left: 20px;

                    &:before {
                        content: "\e909";
                        color: #d6a33a;
                        font-family: 'icomoon' !important;
                        position: absolute;
                        top: 0;
                        left: 0;
                        font-size: 14px;
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    .page-template-template-cemeteries {

        .cemetries-map {
            .cemetries-map-layout {
                #mapSemetries {
                    height: 360px;
                }
            }
        }

        .cemetries-list {
            .cemetries-list-layout {
                
                .list-layout-item {
                    padding: 20px 40px;
                }
            }
        }
    }
}
