section.testimonials {
    .testimonials-wrap {
        box-shadow: 0 0 16px hsla(0,0%,43%,.4);
        margin: 0 0 2rem;

        .testimonials-layout {
            display: flex;
            align-items: center;
            padding: 1rem;

            .item-img {
                background-color: $global-primary-background;
                border-radius: 50%;
                display: flex;
                height: 70px;
                width: 70px;
                min-width: 70px;
                align-items: center;
                justify-content: center;
                margin-right: 1rem;

                img {
                    max-width: 40px;
                }
            }

            .item-info {

                flex-grow: 1;

                .info-title {
                    font-size: 18px;
                    font-weight: bold;
                }
    
                .info-text p {
                    margin: 0;
                }
            }
        }
    }
}