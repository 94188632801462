.search-results,
.search-no-results {
    section.search-result {
        .search-action {
            display: flex;
            align-items: center;
            flex-wrap: wrap;

            max-width: 750px;
            margin: 0 auto;

            #searchform {
                display: flex;
                align-items: center;
                width: 100%;

                input[type=text] {
                    margin-right: 1rem;
                }

                input[type=submit] {
                    background-color: $global-secondary-background;
                    color: $global-inverse-color;
                    padding: 8px 14px;
                    min-width: auto;
                }

                .result-count {
                    width: 100%;
                }
            }
        }

        .search-result-layout {
            display: flex;
            flex-wrap: wrap;

            padding: 1rem 0;

            .layout-item {
                border: 1px solid #00398A;
                padding: 1rem;
                margin: 1rem 10px;
                width: 270px;
                min-height: 160px;

                .item-title {
                    display: inline-block;
                    font-size: 16px;
                    color: $global-primary-background;
                    text-transform: uppercase;
                    margin-bottom: 8px;
                    text-decoration: underline;
                    font-weight: bold;

                    &:hover,
                    &:active,
                    &:focus {
                        color: $global-link-hover-color;
                    }
                }

                .item-time {
                    display: inline-block;
                    margin-bottom: 8px;
                }
            }
        }
        .search-error {
            font-size: 24px;
            margin: 1rem auto;
            text-align: center;
        }
    }
}
