section.banner-home {
    margin-bottom: 1rem;
    
    .container {
        padding: 0;
        max-width: none;

        .banner-home-layout {
            display: flex;
            align-items: stretch;
    
            .banner-item {
                display: flex;
                max-width: 50%;
                flex-grow: 1;
                min-height: 380px;
    
                background-repeat: no-repeat; 
                background-size: cover;
                background-position: center center;
    
                .item-layout {
                    display: flex;
                    flex-direction: column;
                    align-items: center;
                    justify-content: center;
                    width: 100%;
                    background-color: rgba(0,0,0,0.4);
                    padding: 1rem;
                    outline: 1px solid $global-inverse-color;
                    outline-offset: -1rem;
                    transition: 0.2s;

                    &:hover {
                        background-color: rgba(0,0,0,0);
                        transition: 0.2s;
                    }
    
                    .item-title {
                        font-family: $montserrat-font-family;
                        font-size: 36px;
                        color: $global-inverse-color;
                        font-weight: 600;
                        text-align: center;
                    }

                    .item-subtitle {
                        font-size: 18px;
                        color: $global-inverse-color;
                        margin-bottom: 14px;
                        text-align: center;
                    }

                    .item-action {
                        &:hover,
                        &:active,
                        &:focus {
                            background-color: $global-secondary-background;
                        }
                    }

                    .item-details {
                        display: flex;
                        justify-content: space-around;
                        align-items: center;
                        text-align: center;

                        padding: 1rem;
                        width: 100%;

                        .details-wrap {
                            padding: 8px;

                            span {
                                font-size: 4rem;
                            }
                            .item-text {
                                font-size: 1.2rem;
                                color: $global-inverse-color;
                                font-weight: 600;
                                text-transform: uppercase;
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (max-width: 1000px) {
    section.banner-home {
        .container {
            padding: 0 1rem;

            .banner-home-layout {
                flex-direction: column;
        
                .banner-item {
                    max-width: 100%;
                    flex-grow: 1;
                    width: 100%;
                    margin-bottom: 1rem;
                    min-height: 320px;

                    .item-layout {
                        .item-details {
                            .details-wrap {
                                span {
                                    font-size: 2.4rem;
                                }

                                .item-text {
                                    font-size: 14px;
                                }
                            }
                        }
                    }
                }
            }
        }
    }
}

@media screen and (min-width: 2000px) {
    section.banner-home {
        .container {
            .banner-home-layout {
                .banner-item {
                    min-height: 580px;
                }
            }
        }
    }
}