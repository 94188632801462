section.welcome {
    margin-bottom: 4rem;

    .welcome-layout {
        display: flex;
        align-items: center;
        padding: 0 0 3rem 0;

        .item-title {
            min-width: 270px;
            color: $global-primary-background;
            font-size: 2.2rem;
            font-family: $montserrat-font-family;

            h1 {
                font-weight: bold;
                font-size: inherit;
                font-family: inherit;
            }
        }

        .item-text {
            font-size: 14px;
        }
    }

    .announcements {
        border: 1px solid $global-primary-background;
        padding: 2rem;

        .announcement-text {
            color: $global-primary-background;
            text-align: center;
            font-size: 18px;

            a {
                position: relative;

                &::before {
                    position: absolute;
                    content: "\e901";
                    font-family: 'icomoon';
                    right: -26px;
                    top: 7px;
                    font-size: 8px;
                }

                &:hover,
                &:active,
                &:focus {
                    text-decoration: underline;
                }
            }
        }
    }
}

@media screen and (max-width: 768px) {
    section.welcome {
        .welcome-layout {
            flex-direction: column;
    
            .item-title {
                font-size: 1.8rem;
                text-align: center;
            }
        }
    }
}