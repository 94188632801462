section.contact-form {
    .contact-form-layout {
        max-width: 770px;
        margin: 0 auto;

        .layout-item {
            margin: 1rem 0;

            input.warn_border,
            textarea.warn_border {
                border: 1px solid $global-warning-background;
            }

            span.asterix {
                color: $global-warning-background;
            }
        }

        .item-slice {
            display: flex;
            justify-content: space-between;
            flex-wrap: wrap;

            & > div {
                flex-grow: 1;
                max-width: 48%;
            }
        }

        .item-action {
                display: flex;
                justify-content: center;
                flex-wrap: wrap;


            input.main {
                margin: 1rem;
                background-color: $global-link-color;

                &:hover,
                &:active,
                &:focus {
                    background-color: $global-secondary-background;
                }
            }

            input.second {
                margin: 1rem;
                background-color: $global-secondary-background;

                &:hover,
                &:active,
                &:focus {
                    background-color: $global-link-hover-color;
                }
            }
        }

        .message {
            display: flex;
            flex-direction: column;
            align-items: center;
            padding: 1rem;
            background-color: #b4e6c8;
            margin-bottom: 2rem;

            .close {
                display: none;
            }
        }
    }
}