section.contact-map {
    margin-bottom: 3rem;

    .contact-map-layout {
      #mapContact {
        width: 100%;
        height: 400px;
        background-color: grey;
      }
    }
}

